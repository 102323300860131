import {Injectable} from '@angular/core';
import {
  HttpEvent, HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {
      if ([401, 403].includes(err.status)) {
        if (req.url.includes('login') || req.url.includes('verify')) {
          return throwError(err);
        } else {
          this.authService.logout();
        }
      }

      return throwError(err);
    }));
  }
}
