import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AuthService} from "../auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
  verifyGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private auth: AuthService, private router: Router) {
  }

  ngOnInit() {
    this.verifyGroup = this.formBuilder.group({
      verify_code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    });
  }

  verify() {
    if (this.verifyGroup.valid) {
      const isAdmin = this.auth.getCookie('from') === 'admin';
      this.auth.verify(this.verifyGroup.value.verify_code, this.auth.getCookie('toEmail'), isAdmin).subscribe(() => {
        this.router.navigate([isAdmin ? '/admin' : '']);
        this.auth.deleteCookie('from');
      }, error => {
        const errors: any = {};
        errors[error.error.msg] = true;
        this.verifyGroup.get('verify_code').setErrors(errors);
      });
    }
  }

}
