import {Component, OnInit} from '@angular/core';
import {RequestsService} from '../../requests.service';
import {Chart} from 'angular-highcharts';
import {DataService} from '../../data.service';

function sortDateMonthYear(l: string[]) {
    return l.sort((a: string, b: string) => {
        const sa: any[] = a.split('.').map(x => Number(x));
        const sb: any[] = b.split('.').map(x => Number(x));
        return (new Date(sa[1], sa[0], 1)).getTime() - (new Date(sb[1], sb[0], 1)).getTime();
    });
}

@Component({
    selector: 'app-admin-sales',
    templateUrl: './admin-sales.component.html',
    styleUrls: ['./admin-sales.component.css']
})
export class AdminSalesComponent implements OnInit {
    newSales;
    adminSales;
    sales;
    date;
    data;
    chatsData;

    kosher;
    self_connect;
    users;
    companies;
    status;
    dateFilter;

    currentDate;
    nextDate;

    search;
    searchAll = [];
    searchAllValue;
    dataSource;
    displayedColumns = ['company', 'track', 'user', 'lines'];
    filter;

    chartCompanies: Chart;
    chartUsers: Chart;
    selected = 0;

    loadingNewSales;
    loadingAdminSales;
    loadingSales;
    loadingSearchAll;

    constructor(private requests: RequestsService, private dataService: DataService) {
    }

    ngOnInit() {
        // this.getData();
        this.getNewSales();
        this.getAdminSales();
        this.currentMonth();
        this.getSaleCharts();
    }

    nextTab() {
        if (this.selected < 3) {
            this.selected++;
        }
    }

    previousTab() {
        if (this.selected > 0) {
            this.selected--;
        }
    }

    currentMonth() {
        this.date = new Date();
        this.filterSuccessOrFailSales();
    }

    addMonth() {
        const today = new Date();
        today.setMonth(today.getMonth() - 1);
        if (this.date < today) {
            this.date.setMonth(this.date.getMonth() + 1);
            this.filterSuccessOrFailSales();
        }
    }

    lessMonth() {
        this.date.setMonth(this.date.getMonth() - 1);
        this.filterSuccessOrFailSales();
    }

    getSaleCharts() {
        this.requests.get('/admin/sale_charts').subscribe((value: any) => {
            this.chatsData = value;
            this.setChartCompanies();
            this.setChartUsers();
        });
    }

    filterSuccessOrFailSales() {
        this.loadingSales = true;
        this.currentDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
        this.nextDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
        this.nextDate.setMonth(this.nextDate.getMonth() + 1);
        // this.requests.get('/sales', {'between_date': this.currentDate.getTime() + ',' + this.nextDate.getTime()}).subscribe(value => {
        this.requests.get('/api/info/sales',
            {'between_date': this.currentDate.getTime() + ',' + this.nextDate.getTime()}).subscribe(value => {
            this.data = value;
            this.sales = value;
            this.fillUsers(this.sales);
            this.companies = this.ArrNoDupe(this.sales.map(x => x.track.company));
            this.users = this.ArrNoDupe(this.sales.map(x => x.user));
            this.kosher = [{value: true, active: true, name: 'כשר'}, {value: false, active: true, name: 'לא כשר'}];
            this.self_connect = [{value: true, active: true, name: 'חיבורים שלא מהמוקד'}, {
                value: false,
                active: true,
                name: 'חיבורים מהמוקד'
            }];
            this.status = [{value: 1, active: true, name: 'הצליח'}, {value: 2, active: true, name: 'נכשל'},
                {value: 3, active: true, name: 'בהמתנה'}, {value: 5, active: true, name: 'נשלח'}];

            this.getTable();
            this.loadingSales = false;
        }, () => this.loadingSales = false);
    }

    fillUsers(item) {
        if (this.dataService.users) {
            item.forEach(x => {
                const finder = this.dataService.users.find(u => u.username === x.user);
                x.user = finder ? (finder.first_name + ' ' + finder.last_name) : x.user;
            });
        }
    }

    getNewSales() {
        this.loadingNewSales = true;
        // this.requests.get('/sales', {status: 0, not_self_connect: true}).subscribe((value: any) => {
        this.requests.get('/api/info/sales', {status: 0, not_self_connect: true}).subscribe((value: any) => {
            this.loadingNewSales = false;
            this.newSales = value;
            this.fillUsers(this.newSales);
        }, () => this.loadingNewSales = false);
    }

    getAdminSales() {
        this.loadingAdminSales = true;
        // this.requests.get('/sales', {status: 4, not_self_connect: true}).subscribe((value: any) => {
        this.requests.get('/api/info/sales', {status: 4, not_self_connect: true}).subscribe((value: any) => {
            this.loadingAdminSales = false;
            this.adminSales = value;
            this.fillUsers(this.adminSales);
        }, () => this.loadingAdminSales = false);
    }


    setChartUsers() {
        const tmp = {};
        const users = [];
        this.chatsData.forEach(x => {
            const d = new Date(x.date['$date']);
            const month = (d.getMonth() + 1) + '.' + d.getFullYear();
            if (!(month in tmp)) {
                tmp[month] = {};
            }
            if (!(x.user in tmp[month])) {
                tmp[month][x.user] = 0;
            }
            if (!users.includes(x.user)) {
                users.push(x.user);
            }
            tmp[month][x.user] += x.lines.length;
        });
        const categories = sortDateMonthYear(Object.keys(tmp));
        const series = [];
        users.forEach(user => {
            const data = [];
            categories.slice(0, 5).forEach(category => data.push(tmp[category][user] || 0));
            series.push({
                visible: false,
                name: user,
                data: data
            });
        });
        series[0].visible = true;
        this.chartUsers = new Chart({
            chart: {
                type: 'line'
            },
            credits: {
                enabled: false
            },
            title: {
                text: 'מספר קוים שנויידו לפי סוכן ב- 5 חודשים אחרונים'
            },
            xAxis: {
                categories: categories
            },
            yAxis: {
                title: {text: 'מספר קוים שנויידו'}
            },
            series: series
        });
    }

    setChartCompanies() {
        const tmp = {};
        const companies = [];
        this.chatsData.forEach(x => {
            const d = new Date(x.date['$date']);
            const month = (d.getMonth() + 1) + '.' + d.getFullYear();
            if (!(month in tmp)) {
                tmp[month] = {};
            }
            if (!(x.track.company in tmp[month])) {
                tmp[month][x.track.company] = 0;
            }
            if (!companies.includes(x.track.company)) {
                companies.push(x.track.company);
            }
            tmp[month][x.track.company] += x.lines.length;
        });
        const categories = sortDateMonthYear(Object.keys(tmp));
        const series = [];
        companies.forEach(company => {
            const data = [];
            categories.slice(0, 5).forEach(category => data.push(tmp[category][company] || 0));
            series.push({
                name: company,
                data: data
            });
        });
        this.chartCompanies = new Chart({
            chart: {
                type: 'line'
            },
            credits: {
                enabled: false
            },
            title: {
                text: 'מספר קוים שנויידו לפי חברה ב- 5 חודשים אחרונים'
            },
            xAxis: {
                categories: categories
            },
            yAxis: {
                title: {text: 'מספר קוים שנויידו'}
            },
            series: series
        });
    }

    selectOrUnselectAll(list: any[], newActive) {
        list.forEach(x => x.active = newActive.checked);
    }

    someActive(list: any[]) {
        return list ? list.some(x => x.active) : false;
    }

    ArrNoDupe(a) {
        const tmp = [];
        a.forEach(x => {
            if (tmp.indexOf(x) < 0) {
                tmp.push(x);
            }
        });
        return tmp.map(x => {
            return {name: x, active: true};
        });
    }

    isBetween(date, event) {
        if (event) {
            const value = event.getTime();
            return value < date && date < value + 86400000;
        }
        return true;
    }

    getTable(event?) {
        if (event !== undefined) {
            this.dateFilter = event;
        }
        this.filter = [];
        this.sales.forEach(sale => {
            if (this.companies.filter(x => x.active).map(x => x.name).includes(sale.track.company) &&
                this.users.filter(x => x.active).map(x => x.name).includes(sale.user) &&
                this.status.filter(x => x.active).map(x => x.value).includes(sale.status) &&
                this.kosher.filter(x => x.active).map(x => x.value).includes(sale.track.kosher) &&
                this.isBetween(sale.date['$date'], this.dateFilter) &&
                this.self_connect.filter(x => x.active).map(x => x.value).includes(sale.self_connect)) {
                this.filter.push(sale);
            }
        });
        const tmp = [];
        this.filter.forEach(sale => {
            tmp.push({company: sale.track.company, track: sale.track.name, user: sale.user, lines: sale.lines.length});
        });
        this.dataSource = tmp;
    }

    getTotalLines() {
        return this.dataSource.map(t => t.lines).reduce((acc, value) => acc + value, 0);
    }

    getFile() {
        this.requests.post('/get_csv_path', {
            data: this.filter,
            file_name: 'allReport',
            type: 'sale'
        }).subscribe((value: any) => {
            window.open(this.requests.serverURL + '/get_csv_file/' + value.filename);
        });
    }

    onSearchChange() {
        this.searchAll = [];
        this.loadingSearchAll = true;
        this.requests.get('/admin/search_sale?q=' + this.searchAllValue).subscribe((value: any[]) => {
            this.searchAll = value;
            this.fillUsers(this.searchAll);
            this.loadingSearchAll = false;
        }, () => this.loadingSearchAll = false);
    }
}

