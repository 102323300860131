import {Routes} from '@angular/router';
import {AuthAdminGuard, AuthGuard, ExitGuard} from './auth.guard';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './user/home/home.component';
import {NewSaleComponent} from './user/new-sale/new-sale.component';
import {LoginAdminComponent} from './login-admin/login-admin.component';
import {AdminComponent} from './admin/admin.component';
import {AdminCompaniesComponent} from './admin/admin-companies/admin-companies.component';
import {AdminHomeComponent} from './admin/admin-home/admin-home.component';
import {AdminTrackComponent} from './admin/admin-track/admin-track.component';
import {UserComponent} from './user/user.component';
import {TracksComponent} from './user/tracks/tracks.component';
import {MySalesComponent} from './user/my-sales/my-sales.component';
import {ClientsComponent} from './user/clients/clients.component';
import {AdminClientsComponent} from './admin/admin-clients/admin-clients.component';
import {AdminUsersComponent} from './admin/admin-users/admin-users.component';
import {RegisterComponent} from './register/register.component';
import {NewPasswordComponent} from './new-password/new-password.component';
import {AdminSalesComponent} from './admin/admin-sales/admin-sales.component';
import {UserSpaceComponent} from './user/user-space/user-space.component';
import {SettingComponent} from './user/setting/setting.component';
import {UserServiceComponent} from './user/user-service/user-service.component';
import {AdminServiceComponent} from './admin/admin-service/admin-service.component';
import {ChangeSimsComponent} from './user/change-sims/change-sims.component';
import {AdminChangeSimsComponent} from './admin/admin-change-sims/admin-change-sims.component';
import {AdminRepeatedMobilityComponent} from './admin/admin-repeated-mobility/admin-repeated-mobility.component';
import {OperationSimComponent} from './user/operation-sim/operation-sim.component';
import {RepeatedMobilityComponent} from './user/repeated-mobility/repeated-mobility.component';
import {AdminBillingComponent} from './admin/admin-billing/admin-billing.component';
import {SimReleaseComponent} from './user/sim-release/sim-release.component';
import {AdminSimReleaseComponent} from './admin/admin-sim-release/admin-sim-release.component';
import {AdminOperationComponent} from './admin/admin-operation/admin-operation.component';
import {VerifyComponent} from './verify/verify.component';
import {LabComponent} from './user/lab/lab.component';
import {AdminLibComponent} from './admin/admin-lib/admin-lib.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'verify',
    component: VerifyComponent
  },
  {
    path: 'new_password',
    component: NewPasswordComponent
  },
  {
    path: 'login_admin',
    component: LoginAdminComponent
  },
  {
    path: 'lab',
    component: LabComponent,
  },
  {
    path: '',
    component: UserComponent,
    canActivate: [AuthGuard],
    canDeactivate: [ExitGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        // component: HomeComponent,
        redirectTo: 'tracks',
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'new_sale',
        component: NewSaleComponent,
      },
      {
        path: 'setting',
        component: SettingComponent,
      },
      {
        path: 'tracks',
        component: TracksComponent,
      },
      {
        path: 'clients',
        component: ClientsComponent,
      },
      {
        path: 'my_sales',
        component: MySalesComponent,
      },
      // {
      //   path: 'user_space',
      //   component: UserSpaceComponent,
      // },
      {
        path: 'user_service',
        component: UserServiceComponent,
      },
      {
        path: 'operation_sim',
        component: OperationSimComponent,
        children: [
          {
            path: '',
            redirectTo: 'user_space',
            pathMatch: 'full',
          },
          {
            path: 'user_space',
            component: UserSpaceComponent
          },
          {
            path: 'change_sims',
            component: ChangeSimsComponent,
          },
          {
            path: 'change_sims/:id',
            component: ChangeSimsComponent,
          },
          {
            path: 'repeated_mobility',
            component: RepeatedMobilityComponent,
          },
          {
            path: 'repeated_mobility/:id',
            component: RepeatedMobilityComponent,
          },
          {
            path: 'sim_release',
            component: SimReleaseComponent,
          },
        ]
      },
      // {
      //   path: 'admin',
      //   component: AdminComponent,
      //   canActivate: [AuthAdminGuard]
      // }
    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard, AuthAdminGuard],
    // canDeactivate: [ExitGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: AdminHomeComponent,
      },
      {
        path: 'companies',
        component: AdminCompaniesComponent,
      },
      {
        path: 'lib',
        component: AdminLibComponent,
      },
      {
        path: 'tracks',
        component: AdminTrackComponent,
      },
      {
        path: 'clients',
        component: AdminClientsComponent,
      },
      {
        path: 'users',
        component: AdminUsersComponent,
      },
      {
        path: 'operation',
        component: AdminOperationComponent,
        children: [
          {
            path: '',
            redirectTo: 'sales',
            pathMatch: 'full',
          },
          {
            path: 'sales',
            component: AdminSalesComponent,
          },
          {
            path: 'service',
            component: AdminServiceComponent,
          },
          {
            path: 'change_sims',
            component: AdminChangeSimsComponent,
          },
          {
            path: 'repeated_mobility',
            component: AdminRepeatedMobilityComponent,
          },
          {
            path: 'sim_release',
            component: AdminSimReleaseComponent,
          },
          {
            path: 'billing',
            component: AdminBillingComponent,
          },
        ]
      },
      {
        path: '**',
        redirectTo: '/login_admin',
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];
