import {Injectable} from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {RequestsService} from './requests.service';

export const TOKEN_NAME = 'shukhashivuk_token';
export const REFRESH_TOKEN = 'shukhashivuk_refresh_token';

function setCookie(cname, cvalue) {
    document.cookie = cname + '=' + cvalue + ';';
}

function deleteCookie(cname) {
    if (getCookie(cname)) {
        document.cookie = cname + '=' + '';
    }
}


function getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

@Injectable()
export class AuthService {

    setCookie = setCookie;
    getCookie = getCookie;
    deleteCookie = deleteCookie;
    private refreshTokenTimeout;

    constructor(private http: HttpClient, private router: Router, private requests: RequestsService) {
    }

    getToken(): any {
        // console.log('[GET TOKEN]');
        const token = localStorage.getItem(TOKEN_NAME);
        if (token) {
            return token;
        }
        return '';
    }

    getRefreshToken(): any {
        // console.log('[REFRESH TOKEN]');
        const token = localStorage.getItem(REFRESH_TOKEN);
        if (token) {
            const decoded = jwt_decode(token);
            return token;
        }
        return '';
    }

    setToken(token: any): void {
        // console.log('[SET TOKEN]');
        localStorage.setItem(TOKEN_NAME, token.access_token);
        if (token.refresh_token) {
            localStorage.setItem(REFRESH_TOKEN, token.refresh_token);
        }
    }

    removeToken() {
        // console.log('[REMOVE TOKEN]');
        localStorage.removeItem(TOKEN_NAME);
    }

    decodeToken() {
        return jwt_decode(this.getToken());
    }

    login(username, password, admin?) {
        let url = '/api/auth/login';
        // let url = '/login';
        if (admin) {
            url = '/admin/login';
        }
        return this.http.post<any>(this.requests.serverURL + url, {
            username: username,
            password: password
        }).pipe(map(token => {
            if (token && token.access_token) {
                this.setToken(token);
                this.startRefreshTokenTimer();
            }
        }));
    }

    verify(verifyCode, toEmail, admin?) {
        let url = '/verify';
        if (admin) {
            url = '/admin/verify';
        }
        return this.http.post<any>(this.requests.serverURL + url, {
            verify_code: verifyCode,
            to_email: toEmail
        }).pipe(map(token => {
            if (token && token.access_token) {
                this.setToken(token);
                this.startRefreshTokenTimer();
            }
        }));
    }

    private _logout(navigate) {
        const token = localStorage.getItem(TOKEN_NAME);
        if (token) {
            // this.http.delete<any>(this.requests.serverURL + '/logout').subscribe();
            this.http.delete<any>(this.requests.serverURL + '/api/auth/logout').subscribe();
        }
        this.stopRefreshTokenTimer();
        this.removeToken();
        this.router.navigate([navigate]);
    }

    logout() {
        this._logout('/login');
    }

    refreshToken() {
        const token = localStorage.getItem(TOKEN_NAME);
        if (token) {
            return this.http.post<any>(this.requests.serverURL + '/api/auth/refresh', {})
                .pipe(map((res) => {
                    if (res && res.access_token) {
                        this.setToken(res);
                        this.startRefreshTokenTimer();
                    }
                }));
        }
    }

    tokenExpired() {
        const token = this.getToken();
        if (token) {
            const decoded: any = jwt_decode(token);
            return Date.now() >= (decoded.exp * 1000);
        } else {
            return true;
        }
    }

    logoutAdmin() {
        this._logout('/login_admin');
    }


    private startRefreshTokenTimer() {
        const decoded = jwt_decode(this.getToken());
        const expires = new Date(decoded.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        // console.log(new Date());
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

}
