import {Injectable} from '@angular/core';
import {
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent, HttpUserEvent
} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {RequestsService} from './requests.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private requests: RequestsService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent |
        HttpResponse<any> | HttpUserEvent<any> | any> {
        let token = this.authService.getToken();
        const isLoggedIn = !!token;
        const isApiUrl = request.url.startsWith(this.requests.serverURL);
        if (isLoggedIn && isApiUrl) {
            if (request.url.includes('refresh')) {
                token = this.authService.getRefreshToken();
            }
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return next.handle(request);
    }
}
