import {Component, Inject, OnInit} from '@angular/core';
import {ClientDialogComponent} from '../client-dialog/client-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-repeated-mobility',
  template: '<app-repeated-mobility-card [sim]="data.sim" [isAdmin]="data.isAdmin"></app-repeated-mobility-card>',

})
export class DialogRepeatedMobilityComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ClientDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

}
