import {Component, OnInit} from '@angular/core';
import {RequestsService} from '../requests.service';
import {DataService} from '../data.service';
import {AuthService} from '../auth.service';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
//     template: `
//     <mat-toolbar color="primary" class="rtl">
//       <mat-toolbar-row class="mat-elevation-z6 toolbar">
//           <button mat-icon-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button>
//           <button class="mr-3" mat-icon-button routerLink="/" routerLinkActive="router-link-active">
//               <mat-icon>home</mat-icon>
//           </button>
//         <span class="spacer"></span>
// <!--          <a mat-icon-button routerLink="signup">-->
// <!--              <mat-icon>account_circle</mat-icon>-->
// <!--              <img *ngIf="avatar" [src]="avatar"/>-->
// <!--          </a>-->
//           <button mat-icon-button [matMenuTriggerFor]="userMenu">
//               <mat-icon>account_circle</mat-icon>
//           </button>
// <!--          <span>{{dataService?.config?.projectName}}</span>-->
//         <!--<span><button (click)="logout()" mat-icon-button><mat-icon>exit_to_app</mat-icon></button></span>-->
//       </mat-toolbar-row>
//       <mat-toolbar-row>
//         <span></span>
//       </mat-toolbar-row>
//     </mat-toolbar>
//     <mat-menu #userMenu="matMenu">
//         <button mat-menu-item style="text-align: left !important;" [routerLink]="['/setting']">
//             <span>הגדרות</span>
//             <mat-icon>settings_applications</mat-icon>
//         </button>
//         <button mat-menu-item (click)="logout()" style="text-align: left !important;">
//             <span>יציאה</span>
//             <mat-icon>exit_to_app</mat-icon>
//         </button>
//     </mat-menu>
//     <mat-drawer-container autosize>
//       <mat-drawer class="drawer col-9 col-md-3 col-lg-2" #drawer mode="over">
//           <div class="text-center mt-2">
//               <img style="max-width: 14vh; height: auto" src="/assets/images/shukashivuk_logo.png" alt="Logo">
//           </div>
//           <div class="row rtl" style="margin-top: 16px">
//               <div class="col-8 text-center">
//                   <h5>{{dataService?.user?.first_name}} {{dataService?.user?.last_name}}</h5>
//                   <h6>{{dataService?.user?.username}}</h6>
//               </div>
//               <div class="col-4">
//                   <!--            <img src="https://robohash.org/{{dataService?.user?.username}}" [alt]="dataService?.user?.first_name"-->
//                   <!--                 class="avatar">-->
//                   <img src="/assets/images/avatar.jpeg" [alt]="dataService?.user?.first_name" class="avatar">
//               </div>
//           </div>
//           <mat-list class="rtl" style="margin-top: 8px">
//               <mat-divider></mat-divider>
//               <mat-list-item>
//                   <a target="_blank"
//                      mat-icon-button href="https://chat.whatsapp.com/IOYtZL7YM4xK2YkCIRV6IO">
//                       <img src="https://img.icons8.com/ios/24/000000/whatsapp.png">
//                   </a>
//                   <h4 mat-line>לתמיכה ב-WhatsApp</h4>
//               </mat-list-item>
//               <mat-divider></mat-divider>
//               <!--      <mat-list-item>-->
//               <!--        <button mat-icon-button [routerLink]="['/user_space']">-->
//               <!--          <mat-icon>phonelink_setup</mat-icon>-->
//               <!--        </button>-->
//               <!--        <h4 mat-line>כניסת סוכנים</h4>-->
//               <!--      </mat-list-item>-->
//               <!--          <mat-divider></mat-divider>-->
//               <mat-list-item [routerLink]="['/my_sales']">
//                   <button mat-icon-button>
//                       <mat-icon>card_travel</mat-icon>
//                   </button>
//                   <h4 mat-line>תכניות</h4>
//               </mat-list-item>
//               <mat-divider></mat-divider>
//               <mat-list-item [routerLink]="['/my_sales']">
//                   <button mat-icon-button>
//                       <mat-icon>leaderboard</mat-icon>
//                   </button>
//                   <h4 mat-line>סטטוס העסקאות שלי</h4>
//               </mat-list-item>
//               <mat-divider></mat-divider>
//               <mat-list-item [routerLink]="['/clients']">
//                   <button mat-icon-button>
//                       <mat-icon>supervisor_account</mat-icon>
//                   </button>
//                   <h4 mat-line>לקוחות</h4>
//               </mat-list-item>
//               <mat-divider></mat-divider>
//               <mat-list-item>
//                   <button mat-icon-button>
//                       <mat-icon>info</mat-icon>
//                   </button>
//                   <h4 mat-line>מידע חשוב</h4>
//               </mat-list-item>
//               <mat-divider></mat-divider>
// <!--              <mat-list-item>-->
// <!--                  <button mat-icon-button [routerLink]="['/setting']">-->
// <!--                      <mat-icon>settings</mat-icon>-->
// <!--                  </button>-->
// <!--                  <h4 mat-line>עדכון פרטים אישיים</h4>-->
// <!--              </mat-list-item>-->
// <!--              <mat-divider></mat-divider>-->
//               <!--          <mat-list-item>-->
//               <!--            <button mat-icon-button (click)="logout()">-->
//               <!--              <mat-icon>power_settings_new</mat-icon>-->
//               <!--            </button>-->
//               <!--            <h4 mat-line>יציאה</h4>-->
//               <!--          </mat-list-item>-->
//               <!--          <mat-divider></mat-divider>-->
//           </mat-list>
//       </mat-drawer>
//       <mat-drawer-content>
//           <router-outlet></router-outlet>
//       </mat-drawer-content>
//     </mat-drawer-container>
//     `,
    /*TODO check this part*/
    // template: '<router-outlet *ngIf="checkAllData() && !environment.renovation && !hasError"></router-outlet>' +
    //     '<div style="height: 80vh" *ngIf="!checkAllData() && !environment.renovation && !hasError" >' +
    //     '<mat-spinner class="loading"></mat-spinner>' +
    //     '</div>' +
    //     '<div *ngIf="hasError" class="loading text-center">' +
    //     '<p><mat-icon>error</mat-icon></p>' +
    //     '<p>נתקלנו בבעיה, בדוק את החיבור לאינטרנט, או נסה להיכנס שוב מאוחר יותר</p>' +
    //     '<button class="btn btn-primary" (click)="authService.logout()">יציאה</button>' +
    //     '</div>' +
    //     '<app-renovations *ngIf="environment.renovation"></app-renovations>',
})
export class UserComponent implements OnInit {

    hasError = false;
    public isExpanded = true;

    constructor(private requests: RequestsService, public dataService: DataService, public authService: AuthService) {
    }

    ngOnInit() {
        this.getUser();
        this.getCompanies();
        this.getTracks();
        this.getSales();
        this.getClientsIds();
    }

    checkAllData() {
        return this.dataService.clients_ids
            && this.dataService.user
            && this.dataService.companies
            && this.dataService.tracks
            && this.dataService.sales;
    }

    getUser() {
        this.requests.get('/api/auth/get_user').subscribe(value => this.dataService.user = value, () => this.hasError = true);
    }

    getCompanies() {
        this.requests.get('/api/info/get_companies').subscribe(value => this.dataService.companies = value, () => this.hasError = true);
    }

    getTracks() {
        this.requests.get('/api/info/get_tracks').subscribe(value => this.dataService.tracks = value, () => this.hasError = true);
    }

    getClientsIds() {
        this.requests.get('/api/info/get_clients_ids').subscribe(value => this.dataService.clients_ids = value, () => this.hasError = true);
    }

    getSales() {
        this.requests.get('/api/info/sales').subscribe(value => this.dataService.sales = value, () => this.hasError = true);
    }

    logout() {
        this.dataService.sales = null;
        this.dataService.tracks = null;
        this.dataService.companies = null;
        this.dataService.user = null;
        this.authService.logout();
    }


}
