import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RequestsService} from '../requests.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../login/login.component.css']
})
export class RegisterComponent implements OnInit {
  newUser: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private snackBar: MatSnackBar, private requests: RequestsService) {
  }

  ngOnInit() {
    this.newUser = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10),
        Validators.pattern('^05.+')]],
      email_group: ['', [Validators.email]],
    });
  }

  register() {
    this.requests.post('/register', this.newUser.value).subscribe(value => {
      this.newUser.disable();
      this.snackBar.open('בקשתך להצטרף נקלטה בהצלחה! אנו ניצור איתך קשר, תודה.', null, {duration: 10000, direction: 'rtl'});
    }, error => this.newUser.setErrors({}));

  }

}
