import {Component, OnInit} from '@angular/core';
import {RequestsService} from '../../requests.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DataService} from '../../data.service';
import {ActivatedRoute} from '@angular/router';

interface Line {
  sim_num: string;
  subscription_number: string;
}

@Component({
  selector: 'app-change-sims',
  templateUrl: './change-sims.component.html',
  styleUrls: ['./change-sims.component.css']
})
export class ChangeSimsComponent implements OnInit {

  group: UntypedFormGroup;
  lines: Line[] = [];
  reasons = ['סים תקול', 'אובדן או גניבה'];
  update_sim;

  sims;

  constructor(private request: RequestsService, private formBuilder: UntypedFormBuilder, public dataService: DataService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.getSims();
    this.group = this.formBuilder.group({
      first_name: [null, [Validators.required, Validators.minLength(2)]],
      last_name: [null, [Validators.required, Validators.minLength(2)]],
      client_id: [null, [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      company: [null, Validators.required],
      reason: [null, Validators.required],
      last_payment: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      lines: [[]],
      comment: [null],
    });
    this.addLine();
  }

  getSims() {
    this.request.get('/change_sims').subscribe(value => {
      this.sims = value;
      this.route.params.subscribe(params => {
        if (params.id) {
          this.request.get('/change_sims/' + params.id).subscribe(sim => {
            this.update_sim = sim;
            this.group.get('first_name').setValue(this.update_sim.first_name);
            this.group.get('last_name').setValue(this.update_sim.last_name);
            this.group.get('client_id').setValue(this.update_sim.client_id);
            this.group.get('company').setValue(this.update_sim.company);
            this.group.get('reason').setValue(this.update_sim.reason);
            this.group.get('last_payment').setValue(this.update_sim.last_payment);
            // this.group.get('lines').setValue(this.update_sim.lines);
            this.group.get('comment').setValue(this.update_sim.comment);

            this.lines = this.update_sim.lines;
          });
        }
      });
    });
  }

  post() {
    if (this.group.valid && this.simsValid()) {
      this.group.get('lines').setValue(this.lines);
      if (!this.update_sim) {
        this.request.post('/change_sims', this.group.value).subscribe(value => this.sims = value);
      } else {
        const data = this.group.value;
        data.status = 0;
        this.request.post('/update_change_sims/' + this.update_sim._id['$oid'], data).subscribe(value => this.sims = value);
      }
      this.group.reset();
      this.lines = [];
    }
  }

  simsValid() {
    return this.lines.length > 0 && this.lines.every(x => (x.subscription_number && x.subscription_number.length === 10) &&
      (x.sim_num));
  }

  addLine() {
    this.lines.push({sim_num: '', subscription_number: ''});
  }

  removeLine(line) {
    const index = this.lines.indexOf(line);
    if (index > -1 && this.lines.length > 1) {
      this.lines.splice(index, 1);
    }
  }

  setCompany($event: any) {
    this.group.get('company').setValue($event);
  }

  setReason($event: any) {
    this.group.get('reason').setValue($event);
  }
}
