import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RequestsService} from '../requests.service';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['../login/login.component.css']
})
export class LoginAdminComponent implements OnInit {
  loginGroup: UntypedFormGroup;
  error;

  constructor(private auth: AuthService, private router: Router, private formBuilder: UntypedFormBuilder, private req: RequestsService) {
  }

  ngOnInit() {
    this.loginGroup = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern(/[\S]/), Validators.email]],
      password: ['', [Validators.required, Validators.pattern(/[\S]/)]]
    });

    // this.loginGroup.valueChanges.subscribe(() => {
    //   this.error = null;
    // });
  }

  login() {
    if (this.loginGroup.valid) {
      this.auth.login(this.loginGroup.value.username, this.loginGroup.value.password, true)
        .subscribe(() => {
          if (this.auth.getToken()) {
            this.router.navigate(['/admin']);
          } else {
            this.auth.setCookie('toEmail', this.loginGroup.value.username);
            this.auth.setCookie('from', 'admin');
            this.router.navigate(['/verify']);
          }
        }, error => {
          this.error = error.error.msg ? error.error.msg : null;
          this.loginGroup.get('username').setValue(null);
          this.loginGroup.get('password').setValue(null);
        });
    }
  }

}
