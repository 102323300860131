import {Component, OnInit, ViewChild} from '@angular/core';
import {RequestsService} from '../../requests.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-admin-clients',
  templateUrl: './admin-clients.component.html',
  styleUrls: ['./admin-clients.component.css']
})
export class AdminClientsComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  dataSource;
  displayedColumns: string[] = ['client_id', 'first_name', 'last_name', 'address', 'house', 'flat', 'city', 'phone',
    'email'];

  constructor(private requests: RequestsService) {
  }

  ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'מספר שורות בדף';
    this.requests.get('/api/info/get_clients').subscribe((value: any) => {
      this.dataSource = new MatTableDataSource(value);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
