import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {RequestsService} from '../../requests.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-admin-billing',
  templateUrl: './admin-billing.component.html',
  styleUrls: ['./admin-billing.component.css']
})
export class AdminBillingComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  searchAll;
  loadingSearchAll;
  searchAllValue;

  displayedColumns = ['date', 'status', 'debit_approve_number', 'error', 'price', 'client_id', 'first_name', 'last_name'];
  dataSource;

  constructor(private requests: RequestsService, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.getAllBilling();
  }

  getAllBilling() {
    this.requests.get('/admin/billing').subscribe((value: any[]) => {
      const dataSource = [];
      value.forEach(x => {
        dataSource.push({
          date: new Date(x.date['$date']),
          status: x.res.StatusCode === '000' ? 'הצליח' : 'נכשל',
          debit_approve_number: x.res.StatusCode === '000' ? x.res.ResultData.DebitApproveNumber : null,
          error: x.res.StatusCode === '000' ? null : x.res.ErrorMessage,
          price: x.res.ResultData ? Number(x.res.ResultData.DebitTotal) / 100 : null,
          client_id: x.client.client_id,
          first_name: x.client.first_name,
          last_name: x.client.last_name
        });
      });
      this.dataSource = new MatTableDataSource(dataSource);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  onSearchChange() {
    this.searchAll = [];
    this.loadingSearchAll = true;
    this.requests.get('/admin/search_sale?q=' + this.searchAllValue).subscribe((value: any[]) => {
      this.searchAll = value;
      this.loadingSearchAll = false;
    }, () => this.loadingSearchAll = false);
  }

}

@Component({
  template: '<h1 mat-dialog-title>חיוב לקוח</h1>' +
    '<div mat-dialog-content>' +
    '  <p>מה הסכום לחיוב?</p>' +
    '  <mat-form-field>' +
    '    <input matInput [(ngModel)]="data.price" type="number" min="0" step="0.01">' +
    '  </mat-form-field>' +
    '</div>' +
    '<div mat-dialog-actions>' +
    '  <button mat-button (click)="onNoClick()">ביטול</button>' +
    '  <button mat-button [mat-dialog-close]="data.price" cdkFocusInitial>חייב</button>' +
    '</div>',
})
// tslint:disable-next-line:component-class-suffix
export class AdminBillingDialog {

  constructor(public dialogRef: MatDialogRef<AdminBillingDialog>, @Inject(MAT_DIALOG_DATA) public data) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
