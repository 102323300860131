import {Component, OnInit} from '@angular/core';
import {DataService} from '../../data.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
})
export class SettingComponent implements OnInit {

  constructor(private dataService: DataService) {
  }

  user;

  ngOnInit() {
    this.user = this.dataService.user;
  }

}
