import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-renovations',
  templateUrl: './renovations.component.html',
  styleUrls: ['./renovations.component.css']
})
export class RenovationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
