import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BarecodeScannerLivestreamComponent} from 'ngx-barcode-scanner';

@Component({
  selector: 'app-lines',
  templateUrl: './lines.component.html',
  styleUrls: ['../new-sale.component.css']
})
export class LinesComponent implements OnInit {
  @ViewChild(BarecodeScannerLivestreamComponent, {static: true})
  barcodeScanner: BarecodeScannerLivestreamComponent;

  @Input() lines: any[];
  @Input() track;
  @Output() linesChange: EventEmitter<any> = new EventEmitter<any>();

  line;

  simPattern = 'חדש';

  constructor() {
  }

  ngOnInit() {
  }

  onValueChanges(result) {
    this.line.sim = result.codeResult.code;
    this.barcodeScanner.stop();
  }

  addLine() {
    this.lines.push({sim: '', phone: ''});
  }

  removeLine(line) {
    const index = this.lines.indexOf(line);
    if (index > -1 && this.lines.length > 1) {
      this.lines.splice(index, 1);
    }
  }

  startBarcode(line) {
    this.line = line;
    this.barcodeScanner.start();
  }

  InvalidSim(sim: any) {
    return !sim || sim.length < 18;
  }

  getSimErrorMessage(sim: any) {
    if (!sim) {
      return 'נא להזין מספר סים';
    } else if (sim.length < 18) {
      return 'מספר הסים חייב להכיל לפחות 18 ספרות';
    }
    // if (this.lines.filter(l => l.sim === sim).length > 1) {
    //   return 'נראה שמספר זה כבר הוזן במערכת';
    // }
  }

  InvalidPhone(phone: string) {
    return !phone || !(phone === 'חדש' || phone.length === 10);
  }

  getPhoneErrorMessage(phone: string) {
    if (!phone) {
      return 'נא להזין מספר פלאפון';
    } else if (!(phone === 'חדש' || phone.length === 10)) {
      return 'מספר הפלאפון חייב להכיל 10 ספרות';
    } else if (phone.toString().startsWith('05')) {
      return 'נא להזין מספר הפלאפון תקין';
    }
  }
}
