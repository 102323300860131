import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class RequestsService {

    // serverURL = 'http://127.0.0.1:5000';
    serverURL = 'https://api.shukashivuk.com';
    ////// serverURL = 'https://api.dayan-tikshoret.co.il';

    constructor(private http: HttpClient) {
    }

    get(url: string, params?: any) {
        return this.http.get(this.serverURL + url, {params});
    }

    delete(url: string) {
        return this.http.delete(this.serverURL + url);
    }

    post(url, body) {
        return this.http.post(this.serverURL + url, body);
    }

    postFiles(url, body: any[]) {
        const formData: FormData = new FormData();
        body.forEach(b => formData.append(b.name, b.file, b.file.name));
        return this.post(url, formData);
    }

    getClient(client_id: string) {
        return this.get('/api/info/get_clients/' + client_id);
    }
}
