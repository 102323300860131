import {Component, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../../data.service';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
})
export class ClientsComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  tableView = true;
  displayedColumns = ['first_name', 'client_id', 'city', 'address', 'phone', 'email', 'actions'];
  dataSource;

  constructor(public dataService: DataService, private router: Router) {
  }

  ngOnInit() {
    if (!this.dataService.clients) {
      this.dataService.setClients();
    }
    this.dataSource = new MatTableDataSource(this.dataService.clients);
    this.dataSource.paginator = this.paginator;
  }

  goNewSale(client) {
    // this.router.navigate(['/new_sale', {client: client.client_id}]);
    this.router.navigate(['/tracks', {client: client.client_id}]);
  }
}
