import {Component, OnInit} from '@angular/core';
import {DataService} from '../../data.service';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-tracks',
  templateUrl: './tracks.component.html',
  styleUrls: ['./tracks.component.css']
})
export class TracksComponent implements OnInit {
  tracks;
  selected = 0;
  kosher = true;
  sort =  'asc';
  showMore = {};
  clientId;

  constructor(public dataService: DataService, private router: Router, private activeRouter: ActivatedRoute) {
  }

  ngOnInit() {
    this.filterTracks();
    this.activeRouter.params.subscribe(params => {
      if (params['client']) {
        this.clientId = params['client'];
      }
    });
  }

  nextTab() {
    if (this.selected < this.dataService.companies.length - 1) {
      this.selected++;
    }
  }

  previousTab() {
    if (this.selected > 0) {
      this.selected--;
    }
  }

  filterTracks(selected?) {
    if (selected !== undefined) {
      this.selected = selected;
    }
    if (this.kosher === undefined) {
      this.tracks = this.dataService?.tracks.filter(x => x.company === this.dataService.companies[this.selected].name);
    } else {
      this.tracks = this.dataService?.tracks.filter(x => x.company === this.dataService.companies[this.selected].name &&
          x.kosher === this.kosher);
    }

    if (this.sort === 'asc') {
      this.tracks.sort((a, b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
    } else {
      this.tracks.sort((a, b) => (a.price < b.price) ? 1 : ((b.price < a.price) ? -1 : 0));
    }
  }

  goNewSale(track) {
    const params = {track: track._id['$oid'] ? track._id['$oid'] : track._id};
    if (this.clientId) {
      params['client'] = this.clientId;
    }
    this.router.navigate(['/new_sale', params]);
  }

}
