import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any[], args?: string, key?: string): any {
    if (!args) {
      return value;
    }
    args = args.toString().toLowerCase();
    if (key) {
      return value.filter(x => {
        if (typeof x[key] === 'object') {
          return Object.values(x[key]).some(y => y ? y.toString().toLowerCase().includes(args) : false);
        } else {
          return x[key] ? x[key].toString().toLowerCase().includes(args) : false;
        }
      });
    } else {
      return value.filter(x => {
        if (typeof x === 'object') {
          return Object.values(x).some(y => y ? y.toString().toLowerCase().includes(args) : false);
        } else {
          return x ? x.toString().toLowerCase().includes(args) : false;
        }
      });
    }
  }

}
