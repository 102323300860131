import {Component, OnInit, ViewChild} from '@angular/core';
import {RequestsService} from '../../requests.service';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;

  users = [];
  usersRegister = [];
  dataSource;
  tableView = true;
  // displayedColumns = ['username', 'first_name', 'last_name', 'email', 'phone'];
  displayedColumns = ['username', 'email', 'actions'];

  constructor(private requests: RequestsService) {
  }

  ngOnInit() {
    this.requests.get('/admin/users').subscribe((value: any) => {
      this.users = value.filter(x => !x.request);
      this.usersRegister = value.filter(x => x.request);
      this.dataSource = new MatTableDataSource(this.users);
      this.dataSource.paginator = this.paginator;
    });
  }

  acceptUser(user) {
    this.requests.post('/admin/users', {
      username: user.username,
      first_name: user.first_name
    }).subscribe((value: any) => {
      this.users = value.filter(x => !x.request);
      this.usersRegister = value.filter(x => x.request);
    });
  }

  save(user) {
    this.requests.post('/admin/users', user).subscribe((value: any) => {
      this.users = value.filter(x => !x.request);
      user.edit = false;
    });
  }

  delete(user) {
    this.requests.delete('/admin/users/' + user.username).subscribe((value: any) => {
      this.users = value.filter(x => !x.request);
      this.usersRegister = value.filter(x => x.request);
    });
  }

  updateRetry(user: any) {
    this.requests.post('/admin/users', {
      edit: true,
      username: user.username,
      retries: 0,
      verify_retries: 0
    }).subscribe((value: any) => {
      this.users = value.filter(x => !x.request);
    });
  }

  // getFile() {
  //   this.requests.post('/get_csv_path', {
  //     data: this.dataSource,
  //     file_name: 'allReport',
  //     type: 'users'
  //   }).subscribe((value: any) => {
  //     window.open(this.requests.serverURL + '/get_csv_file/' + value.filename);
  //   });
  // }

}
