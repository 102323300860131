import {Component, OnInit} from '@angular/core';
import {RequestsService} from '../../requests.service';
import {WarnningDialogComponent} from '../../utils/warnning-dialog/warnning-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-admin-track',
  templateUrl: './admin-track.component.html',
  styleUrls: ['./admin-track.component.css']
})
export class AdminTrackComponent implements OnInit {
  companies = [];
  tracks = [];
  data = [];
  tmp = {
    name: '', details: '', price: 0, sms: '',
    internet: '', abroad: '', sim_price: 0, kosher: false, comments: '',
    customer_id: null
  };

  selected = 0;

  constructor(private requests: RequestsService, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.getCompanies();
  }

  nextTab() {
    if (this.selected < this.companies.length - 1) {
      this.selected++;
    }
  }

  previousTab() {
    if (this.selected > 0) {
      this.selected--;
    }
  }

  getCompanies() {
    this.requests.get('/admin/companies?files=false').subscribe((value: any) => this.companies = value, null, () => this.getTracks());
  }

  getTracks() {
    this.requests.get('/admin/tracks').subscribe(value => {
      this.buildData(value);
    });
  }

  buildData(value) {
    this.data = [];
    this.companies.forEach(company => {
      this.data.push({company: company, data: value.filter(x => x.company === company.name)});
    });
  }

  delete(track) {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'מחק מסלול',
        content: 'האם אתה בטוח שברצונך למחוק את ' + track.name,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.requests.delete('/admin/tracks/' + track._id['$oid']).subscribe((value: any) => {
          this.buildData(value);
        });
      }
    });
  }

  edit(track) {
    this.requests.post('/admin/tracks', track).subscribe((value: any) => {
      track.edit = false;
      this.buildData(value);
    });
  }

  add(company_name) {
    this.tmp['company'] = company_name;
    this.requests.post('/admin/tracks', this.tmp).subscribe(value => {
      this.tmp = {
        name: '', details: '', price: 0, sms: '',
        internet: '', abroad: '', sim_price: 0, kosher: false, comments: '',
        customer_id: null
      };
      this.buildData(value);
    });
  }
}
