import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RequestsService} from '../../../requests.service';
import {DataService} from '../../../data.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['../new-sale.component.css']
})
export class CompanyComponent implements OnInit {
  @Input() company;
  @Output() companyChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() block_companies: any[];
  companies;

  constructor(private request: RequestsService, private dataService: DataService) {
  }

  ngOnInit() {
    this.companies = this.dataService.companies.filter(x => !this.block_companies.includes(x.name));
  }

  emit(event) {
    this.companyChange.emit(event.value);
  }

}
