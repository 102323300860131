import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';

import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';


@Component({
  selector: 'app-track-card',
  templateUrl: './track-card.component.html',
  styleUrls: ['./track-card.component.css']
})
export class TrackCardComponent implements OnInit {
  @Input() edit;
  @Input() showMore;

  @Input() track;
  @Output() trackChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TrackDetailsDialogComponent, {
      data: {track: this.track},
      minWidth: '250px'
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

}


@Component({
  selector: 'app-track-details-dialog',
  template: `
    <mat-card class="rtl">
      <mat-card-header>
        <b> פרטי החבילה</b>
      </mat-card-header>
      <mat-card-content>
        <dl>{{data.track.details.trim()}}</dl>
        <dl>שיחות והודעות:</dl>
        <dd>{{data.track.sms}}</dd>
        <dl>אינטרנט:</dl>
        <dd>{{data.track.internet}}</dd>
        <dl>שיחות לחו"ל:</dl>
        <dd>{{data.track.abroad.trim()}}</dd>
        <dl>מחיר סים:</dl>
        <dd>{{data.track.sim_price}}</dd>
        <dl>הערות:</dl>
        <dd>{{data.track.comments.trim()}}</dd>
        <dl>ID</dl>
        <dd>{{data.track.customer_id}}</dd>
      </mat-card-content>
    </mat-card>
  `,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
  ],

})
export class TrackDetailsDialogComponent {
  constructor(public dialogRef: MatDialogRef<TrackDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
  }

}
