import {Component, OnInit} from '@angular/core';
import {RequestsService} from '../../requests.service';

@Component({
  selector: 'app-admin-lib',
  templateUrl: './admin-lib.component.html',
  styleUrls: ['./admin-lib.component.css']
})
export class AdminLibComponent implements OnInit {
  dataSource;
  displayedColumns = ['date', 'email', 'lab_name', 'client_name', 'client_phone', 'business_name',
    'product', 'fix_type', 'price_fix_type', 'replaced', 'price_replaced', 'file_name', 'status', 'save'];

  constructor(public requests: RequestsService) {
  }

  ngOnInit(): void {
    this.requests.get('/admin/admin_lib').subscribe(value => this.dataSource = value);
  }


  save(element) {
    this.requests.post('/admin/update_lib/' + element['_id']['$oid'],
      {
        price_replaced: element.price_replaced,
        price_fix_type: element.price_fix_type,
        status: element.status
      }).subscribe(value => this.dataSource = value);
  }
}
