import {Component, Input, OnInit} from '@angular/core';
import {WarnningDialogComponent} from '../warnning-dialog/warnning-dialog.component';
import {RequestsService} from '../../requests.service';
import {DialogChangeSimComponent} from '../dialog-change-sim/dialog-change-sim.component';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-min-change-sim-card',
  templateUrl: './min-change-sim-card.component.html',
  styleUrls: ['./min-change-sim-card.component.css']
})
export class MinChangeSimCardComponent implements OnInit {
  @Input() sim;
  @Input() isAdmin;

  constructor(private requests: RequestsService, private dialog: MatDialog, private router: Router) {
  }

  ngOnInit() {
  }

  removeSale() {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'מחיקת החלפת סים',
        content: 'האם אתה בטוח שברצונך למחוק החלפת סים זו?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.sim.status = 2;
        this.requests.delete('/admin/change_sims/' + this.sim._id['$oid']).subscribe(value => {
        });
      }
    });
  }

  deleteSale() {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'ביטול החלפת סים',
        content: 'האם אתה בטוח שברצונך לבטל החלפת סים זו?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.sim.status = 2;
        this.requests.post('/update_change_sims/' + this.sim._id['$oid'],
          {status: 2, comment: this.sim.comment}).subscribe(value => {
        });
      }
    });
  }

  editSale() {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'ערוך החלפת סים',
        content: 'האם אתה בטוח שברצונך לערוך החלפת סים זו?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.router.navigate(['operation_sim', 'change_sims', this.sim._id['$oid']]);
      }
    });
  }

  openDialogSim() {
    this.dialog.open(DialogChangeSimComponent, {
      data: {
        sim: this.sim, isAdmin: this.isAdmin
      }, height: '80%', width: '80%'
    });
  }

}
