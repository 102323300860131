import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-client-dialog',
  template: '<app-client-card [client]="data.client"></app-client-card>',
})
export class ClientDialogComponent {

  constructor(public dialogRef: MatDialogRef<ClientDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
  }


}
