import {Component, Input, OnInit} from '@angular/core';
import {RequestsService} from '../../requests.service';
import {Router} from '@angular/router';
import {WarnningDialogComponent} from '../warnning-dialog/warnning-dialog.component';
import {DialogRepeatedMobilityComponent} from '../dialog-repeated-mobility/dialog-repeated-mobility.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-min-repeated-mobility-card',
  templateUrl: './min-repeated-mobility-card.component.html',
  styleUrls: ['./min-repeated-mobility-card.component.css']
})
export class MinRepeatedMobilityCardComponent implements OnInit {

  @Input() sim;
  @Input() isAdmin;

  constructor(private requests: RequestsService, private dialog: MatDialog, private router: Router) {
  }

  ngOnInit() {
  }

  removeSale() {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'מחיקת ניוד חוזר',
        content: 'האם אתה בטוח שברצונך למחוק ניוד חוזר זה?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.sim.status = 2;
        this.requests.delete('/admin/repeated_mobility/' + this.sim._id['$oid']).subscribe(value => {
        });
      }
    });
  }

  deleteSale() {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'ביטול ניוד חוזר',
        content: 'האם אתה בטוח שברצונך לבטל ניוד חוזר זה?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.sim.status = 2;
        this.requests.post('/update_repeated_mobility/' + this.sim._id['$oid'],
          {status: 2, comment: this.sim.comment}).subscribe(value => {
        });
      }
    });
  }

  editSale() {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'ערוך ניוד חוזר',
        content: 'האם אתה בטוח שברצונך לערוך ניוד חוזר זה?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.router.navigate(['operation_sim', 'repeated_mobility', this.sim._id['$oid']]);
      }
    });
  }

  openDialogSim() {
    this.dialog.open(DialogRepeatedMobilityComponent, {
      data: {
        sim: this.sim, isAdmin: this.isAdmin
      }, height: '80%', width: '80%'
    });
  }

}
