import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-pdf-dialog',
  template: '<simple-pdf-viewer style="height: 100%" src="/assets/accept.pdf"></simple-pdf-viewer>',
})
export class PdfDialogComponent {

  constructor(public dialogRef: MatDialogRef<PdfDialogComponent>) {
  }

}
