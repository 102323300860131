import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RequestsService} from '../../requests.service';
import {WarnningDialogComponent} from '../../utils/warnning-dialog/warnning-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-lab',
  templateUrl: './lab.component.html',
  styleUrls: ['./lab.component.css']
})
export class LabComponent implements OnInit {
  form: UntypedFormGroup;
  loading = false;
  message = null;

  labs = [
    'עולם הסלולר  - רחוב רבי עקיבא 123 בני ברק 025382222',
    'אוריאל מובייל - רחוב רבי עקיבא 120 בני ברק 0587817500',
    'עולם הסלולר  - רחוב יפו 216 בניין שערי העיר ירושלים 0546000030',
    'המעבדה המרכזית  - רחוב בר כוכבא 8 חיפה 048622042',
    'אשר פון  - רחוב ירושלים 90 צפת 0539597950',
    'קשר כשר - רחוב יהודה הנשיא  92 אלעד 0548425277',
    'קובי אלעזר - רחוב אריאל שרון 46 נתיבות 0503926511',
    'פיקס פון - רחוב כהנמן 100 בני ברק 0525886603',
    'קווי מהדרין - רחוב יהודה הנשיא 8 אשדוד 0556777773',
    'סמארט בגליל - רחוב הגליל 19 טבריה 0483883104',
  ];

  products = [
    'שיאומי F22 PRO - אחריות 12 חודשים מתאריך הרכישה',
    'שיאומי F21 PRO - אחריות 12 חודשים מתאריך הרכישה',
    'שיאומי F22 החדש - אחריות 12 חודשים מתאריך הרכישה',
    'שיאומי QIN1 - אחריות 12 חודשים מתאריך הרכישה',
    'סמסונג יאנג מחודש ( אחריות 3 חודשים מתאריך מהרכישה)',
    'נוקיה c2 מחודש - ( אחריות 3 חודשים מתאריך מהרכישה)',
    'pocket cobot 3 - אחריות 12 חודשים מתאריך הרכישה',
  ];

  constructor(private fb: UntypedFormBuilder, private requests: RequestsService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      lab_name: ['', Validators.required],
      client_name: ['', Validators.required],
      client_phone: ['', Validators.required],
      business_name: ['', Validators.required],
      product: ['', Validators.required],
      fix_type: ['', Validators.required],
      price_fix_type: ['', Validators.required],
      replaced: ['', Validators.required],
      price_replaced: ['', Validators.required],
      file: ['', Validators.required],
      status: ['0']
    });
  }

  onImagePicked(event: any) {
    const file = event.target.files[0];
    console.log(file);
    this.form.get('file').setValue(file);
    this.form.updateValueAndValidity();
  }

  removeImage() {
    this.form.get('file').setValue('');
    this.form.updateValueAndValidity();
  }

  send() {
    if (this.form.valid) {
      this.loading = true;
      const formData = new FormData();
      Object.keys(this.form.controls).forEach(control => {
        formData.append(control, this.form.controls[control].value);
      });
      this.requests.post('/insert_to_lib', formData).subscribe(value => {
        this.form.reset();
        this.form.updateValueAndValidity();
        this.loading = false;
        this.message = {class: 'alert-success', text: 'תודה, נשלח בהצלחה'};
      }, error => {
        const dialogRef = this.dialog.open(WarnningDialogComponent, {
          data: {
            title: 'לא הצליח',
            content: 'לא הצלחנו לשלוח את הנתונים אנא נסה מאוחר יותר או פנה אלינו לדיווח'
          }
        });
        this.message = {
          class: 'alert-danger',
          text: 'לא הצלחנו לשלוח את הנתונים אנא נסה מאוחר יותר או פנה אלינו לדיווח'
        };
        this.loading = false;
      });
    }
  }
}
