import {Component, Inject, } from '@angular/core';
import {ClientDialogComponent} from '../client-dialog/client-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-change-sim',
  template: '<app-change-sim-card [sim]="data.sim" [isAdmin]="data.isAdmin"></app-change-sim-card>',
})
export class DialogChangeSimComponent {

  constructor(public dialogRef: MatDialogRef<ClientDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

}
