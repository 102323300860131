import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RequestsService} from '../../requests.service';
import {WarnningDialogComponent} from '../warnning-dialog/warnning-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-repeated-mobility-card',
  templateUrl: './repeated-mobility-card.component.html',
  styleUrls: ['./repeated-mobility-card.component.css']
})
export class RepeatedMobilityCardComponent implements OnInit {

  @Input() isAdmin;
  @Input() sim;
  @Output() simChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private requests: RequestsService, private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  toDate(date: any) {
    const d = new Date(date);
    return [d.getDate(), d.getMonth() + 1, d.getFullYear()].join('/') + ' ' +
      [d.getHours() < 10 ? '0' + d.getHours() : d.getHours(),
        d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()].join(':');
  }

  update(status: number) {
    const statusList = ['הצליח', 'ביטול', 'בהמתנה'];
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'עדכון ניוד חוזר',
        content: 'האם אתה בטוח שברצונך לעדכן את הניוד חוזר כ - ' + statusList[status - 1],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.sim.status = status;
        this.requests.post('/admin/repeated_mobility',
          {
            comment: this.sim.comment,
            status: status,
            _id: this.sim._id['$oid'],
          }).subscribe(value => {
          this.simChange.emit(value);
        });
      }
    });
  }

}
