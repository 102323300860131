import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {RequestsService} from '../../../requests.service';
import {TrackDialogComponent} from '../../../utils/track-dialog/track-dialog.component';
import {DataService} from '../../../data.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['../new-sale.component.css']
})
export class TrackComponent implements OnChanges {
  // @ViewChild(MatSelectionList, {static: true}) selectionList: MatSelectionList;
  @Input() company;
  @Input() track;
  @Output() trackChange: EventEmitter<any> = new EventEmitter<any>();
  tracks = [];
  data = [];
  kosher = false;

  constructor(private request: RequestsService, private dialog: MatDialog, private dataService: DataService) {
    this.tracks = dataService.tracks;
  }

  ngOnChanges(changes) {
    if (changes.company && !changes.company.firstChange) {
      this.track = null;
      this.emit(this.track);
    }
    if (changes.track && this.track) {
      this.kosher = this.track.kosher;
    }
    this.filter();
  }

  filter() {
    this.data = this.tracks.filter(x => x.company === this.company && x.kosher === this.kosher);

  }

  emit(value) {
    this.trackChange.emit(value);
  }

  sameId(x) {
    let result = false;
    if (this.track) {
      if (typeof this.track._id === 'string') {
        result = this.track._id === x._id['$oid'];
      } else if (typeof this.track._id === 'object') {
        result = this.track._id['$oid'] === x._id['$oid'];
      }
    }
    return result;
  }

  openDetailsDialog(track) {
    this.dialog.open(TrackDialogComponent, {
      data: {
        track: track,
      }, height: '80%'
    });
  }

}
