import { Component, OnInit } from '@angular/core';
import {RequestsService} from '../../requests.service';

@Component({
  selector: 'app-admin-repeated-mobility',
  templateUrl: './admin-repeated-mobility.component.html',
  styleUrls: ['./admin-repeated-mobility.component.css']
})
export class AdminRepeatedMobilityComponent implements OnInit {

  sims;
  newSims;

  dataSource;
  currentDate;
  nextDate;
  date;


  constructor(private requests: RequestsService) {
  }

  ngOnInit() {
    this.date = new Date();
    this.getSims();
  }

  getSims() {
    this.requests.get('/admin/repeated_mobility').subscribe(value => this.loadData(value));
  }

  filter() {
    this.currentDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    this.nextDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    this.nextDate.setMonth(this.nextDate.getMonth() + 1);
    this.dataSource = this.sims.filter(x => new Date(x.date['$date']) >= this.currentDate &&
      new Date(x.date['$date']) < this.nextDate);
  }

  loadData(data) {
    this.sims = data;
    this.newSims = data.filter(x => x.status === 0);
    this.filter();

  }

  currentMonth() {
    this.date = new Date();
    this.filter();
  }

  addMonth() {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    if (this.date < today) {
      this.date.setMonth(this.date.getMonth() + 1);
      this.filter();
    }
  }

  lessMonth() {
    this.date.setMonth(this.date.getMonth() - 1);
    this.filter();
  }

}
