import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  SimpleChanges
} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

export function ValidateCreditCardNumber(control: AbstractControl) {
  if (!((control.value.isToken || control.value.length >= 8) && control.value.length < 20)) {
    return {'ccInvalid': true};
  }
  return null;
}

export function ValidateCreditCardDate(control: AbstractControl) {
  if (control.value) {
    const split = control.value.split('/');
    const date = new Date([split[0], '01', ('20' + split[1])].join('/'));
    if (date < new Date()) {
      return {'ccDateInvalid': true};
    }
  }
  return null;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['../new-sale.component.css'],
})
export class PaymentComponent implements OnInit, OnChanges {
  creditCard: UntypedFormGroup;
  bankAccount: UntypedFormGroup; // = {number: null, bank: null, account: null};
  disableBankAccountFor = ["רמי לוי תקשורת"]; // TODO move to company config

  token;

  @Input() payment;
  @Input() company;
  @Input() companyDetails;
  @Output() paymentChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: UntypedFormBuilder) {
    this.creditCard = this.formBuilder.group({
      type: ['creditCard'],
      number: ['', [Validators.required, ValidateCreditCardNumber]],
      exp: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5),
        ValidateCreditCardDate]],
      cvv: ['', [Validators.minLength(3), Validators.required]],
      isToken: [null]
    });
    this.bankAccount = this.formBuilder.group({
      type: ['bankAccount'],
      number: ['', Validators.required],
      bank: ['', Validators.required],
      account: ['', Validators.required],
    });
    this.emitCreditCard();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.payment && this.payment.value) {
      if (this.payment.value.type === 'creditCard') {
        this.creditCard.setValue(this.payment.value);
      } else if (this.payment.value.type === 'bankAccount') {
        this.bankAccount.setValue(this.payment.value);
      }
    }
  }

  emitCreditCard() {
    this.paymentChange.emit(this.creditCard.value);
  }

  getPayment(event) {
    if (event.index === 0) {
      this.emitCreditCard();
    } else {
      this.paymentChange.emit(this.bankAccount.value);
    }
  }

  creditCardChange() {
    this.emitCreditCard();
  }

  bankAccountChange() {
    this.paymentChange.emit(this.bankAccount.value);
  }


  getCCErrorMessage(g) {
    const f = this.creditCard.get(g);
    if (g === 'number') {
      if (f.hasError('required')) {
        return 'היי, שכחת להכניס מספר כרטיס :)';
      } else if (f.hasError('ccInvalid')) {
        return 'כרטיס אשראי אינו תקין';
      }
    } else if (g === 'exp') {
      if (f.hasError('required')) {
        return 'היי, שכחת להכניס תוקף :)';
      } else if (f.hasError('minlength') || f.hasError('maxlength')) {
        return 'תוקף חייב להיות בעל 4 ספרות חודש ושנה חח/שש';
      } else if (f.hasError('ccDateInvalid')) {
        return 'פג תוקפו של הכרטיס אשראי :(';
      }
    } else if (g === 'cvv') {
      if (f.hasError('required')) {
        return 'היי, שכחת להכניס CVV :)';
      } else if (f.hasError('minlength') || f.hasError('maxlength')) {
        return 'חייב להיות בעל 3 ספרות';
      }
    }
  }

  getBAErrorMessage(g: string) {
    const f = this.bankAccount.get(g);
    if (g === 'number') {
      if (f.hasError('required')) {
        return 'היי, שכחת להכניס מספר חשבון :)';
      }
    } else if (g === 'account') {
      if (f.hasError('required')) {
        return 'היי, שכחת להכניס סניף :)';
      }
    } else if (g === 'bank') {
      if (f.hasError('required')) {
        return 'היי, שכחת להכניס בנק :)';
      }
    }
  }
}

@Pipe({
  name: 'creditCardMask'
})
export class CreditCardMaskPipe implements PipeTransform {
  transform(plainCreditCard: string): string {
    if (!plainCreditCard) {
      return plainCreditCard;
    }
    const v = plainCreditCard.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = matches && matches[0] || '';
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(' ');
    } else {
      return plainCreditCard;
    }
  }
}

@Pipe({
  name: 'dateMask'
})
export class DateMaskPipe implements PipeTransform {
  transform(plainDate: string): string {
    if (!plainDate) {
      return plainDate;
    }
    const v = plainDate.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{2,4}/g);
    const match = matches && matches[0] || '';
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 2) {
      parts.push(match.substring(i, i + 2));
    }

    if (parts.length) {
      return parts.join('/');
    } else {
      return plainDate;
    }
  }
}
