import {Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RequestsService} from '../../requests.service';
import {DataService} from '../../data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PdfDialogComponent} from '../../utils/pdf-dialog/pdf-dialog.component';
import {CanvasDialogComponent} from '../../utils/canvas-dialog/canvas-dialog.component';
import {Location} from '@angular/common';
import {WarnningDialogComponent} from '../../utils/warnning-dialog/warnning-dialog.component';
import {ValidateCreditCardNumber, ValidateCreditCardDate} from './payment/payment.component';
import {MessageDialogComponent} from '../../utils/message-dialog/message-dialog.component';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

function toExp(token) {
  return token.CreditCardExpDate.slice(0, 2) + '/' + token.CreditCardExpDate.slice(2, 4);
}

function validID(control: AbstractControl): { [key: string]: any } | null {

  if (control.value) {
    const id_12_digits = [1, 2, 1, 2, 1, 2, 1, 2, 1];
    let valid = 0;
    control.value.toString().split('').forEach((num, index) => {
      let tmp = Number(num) * id_12_digits[index];
      tmp = tmp < 10 ? tmp : Math.floor(tmp / 10) + (tmp % 10);
      valid += tmp;
    });
    if (valid % 10 !== 0) {
      return {'clientIdInvalid': true};
    }
  }
  return null;
}

function validFullName(control: AbstractControl): { [key: string]: any } | null {
  if (control.value) {
    const valueList = control.value.split(' ').filter(Boolean);
    if (valueList.length < 2 || valueList.some(x => x.trim().length < 2)) {
        return {'minlength': true};
    }
  }
  return null;
}


@Component({
  selector: 'app-new-sale',
  templateUrl: './new-sale.component.html',
  styleUrls: ['./new-sale.component.css'],
})
export class NewSaleComponent implements OnInit {
  clientFilteredOptions: Observable<any[]>;

  company: any;
  track: any;
  client: UntypedFormGroup;
  payment: UntypedFormGroup;
  paymentCardExists: UntypedFormGroup;
  liens: any[] = [{sim: null, phone: null}];
  // self_connect = null;
  self_connect = false;
  bonus: any = {text: ''};
  comment: '';
  signature;

  clientExists = false;
  validateClientExists = ['פרטנר', 'פלאפון', 'הוט מובייל'];
  activateCall;
  companyDetails;

  date: Date;
  newDate: Date;

  user;
  sale;

  submitted = false;
  snapshotClientID;
  clientTrackIDRRequired;
  searchClientTrackIDRRequired = false;
  messageClientTrackIDRRequired = '';

  constructor(private formBuilder: UntypedFormBuilder, private requests: RequestsService, public dataService: DataService,
              private location: Location, private activeRouter: ActivatedRoute, private snackBar: MatSnackBar,
              private dialog: MatDialog, private router: Router) {
  }


  ngOnInit() {
    if (this.dataService.user.is_admin && !this.dataService.users) {
      this.requests.get('/api/auth/users').subscribe(value => this.dataService.users = value);
    }
    this.activeRouter.params.subscribe(params => {
      if (params['sale']) {
        const sale = this.dataService.sales.find(x => x._id['$oid'] === params.sale);
        if (sale) {
          this.buildSale(sale);
          this.activateCall = true;
        } else {
          this.requests.get('/api/info/sales/' + params.sale).subscribe(value => this.buildSale(value));
        }
      } else {
        if (params['track']) {
          this.track = JSON.parse(JSON.stringify(this.dataService.tracks.find(x => x._id['$oid'] === params.track)));
          this.company = this.track.company;
          this.companyDetails = this.dataService.companies.find(c => c.name === this.company);
        }
        if (params['client']) {
          if (this.dataService.clients) {
            const client = this.dataService.clients.find(x => x.client_id.toString() === params.client);
            this.buildClient(client);
            this.buildPayment(client.payment);
          } else {
            this.requests.getClient(params.client).subscribe((client: any) => {
              this.buildClient(client);
              this.buildPayment(client.payment);
            });
          }
        }
        this.buildPaymentCardExists();
      }
    });
    if (!this.client) {
      this.buildClient();
    }
    if (!this.payment) {
      this.buildPayment();
    }

    this.clientFilteredOptions = this.client.get('client_id').valueChanges
        .pipe(startWith(''), map(value => this._filterClients(value)));

  }

  private _filterClients(value: string): string[] {
    if (value.length < 3) {
      return [];
    }
    const filterValue = value.toString().toLowerCase();

    return this.dataService.clients_ids.filter(option => {
      return option.toString().toLowerCase().includes(filterValue);
    });
  }


  setSelfConnect(event) {
    if (event.value) {
      const d = this.dialog.open(WarnningDialogComponent, {
        data: {
          title: 'סיכום מכירות',
          content: 'האם ברצונך לעדכן מכירה קיימת לתוך קובץ המכירות שלך?',
        }
      });
      d.afterClosed().subscribe(result => {
        if (result === 'ok') {
          this.self_connect = event.value;
        }
      });
    } else {
      const d = this.dialog.open(WarnningDialogComponent, {
        data: {
          title: 'חיבור קווים',
          content: 'האם ברצונך לשלוח חיבור חדש למוקד חיבורי קווים?',
        }
      });
      d.afterClosed().subscribe(result => {
        if (result === 'ok') {
          this.self_connect = event.value;
        }
      });
    }
  }

  goBack() {
    this.location.back();
  }

  buildSale(sale) {
    this.sale = sale._id['$oid'];

    this.track = JSON.parse(JSON.stringify(sale.track ? sale.track :
        this.dataService.tracks.find(x => x._id['$oid'] === sale.track._id)));
    this.company = this.track.company;
    this.buildClient(sale.client);
    this.buildPayment(sale.payment);
    this.liens = sale.lines;
    this.signature = sale.signature;
    this.clientExists = sale.clientExists || false;
    this.comment = sale.comment;
    this.user = sale.user;
    this.bonus = sale.bonus;
    this.self_connect = sale.self_connect;
    this.date = new Date(sale.date['$date']);

    if (!this.companyDetails) {
      this.companyDetails = this.dataService.companies.find(c => c.name === this.company);
    }
    this.validLines();
  }

  buildPayment(payment?) {
    const type = payment ? payment.type : 'creditCard';
    if (type === 'bankAccount') {
      let number = '';
      let bank = '';
      let account = '';
      if (payment) {
        number = payment.number;
        bank = payment.bank;
        account = payment.account;
      }
      this.payment = this.formBuilder.group({
        type: [type],
        number: [number, Validators.required],
        bank: [bank, Validators.required],
        account: [account, Validators.required],
      });
    } else {
      let number = '';
      let exp = '';
      let cvv = '';
      let isToken = null;
      if (payment) {
        const token = payment.token;

        number = payment.number || (token ? payment.token.CreditCardNumber : '');
        exp = payment.exp || (token ? toExp(token) : '');
        cvv = payment.cvv;
        isToken = payment.isToken || (token ? payment.token.Token : null);
      }
      this.payment = this.formBuilder.group({
        type: [type],
        number: [number, [Validators.required, ValidateCreditCardNumber]],
        exp: [exp, [Validators.required, Validators.minLength(5), Validators.maxLength(5),
          ValidateCreditCardDate]],
        cvv: [cvv, [Validators.required, Validators.minLength(3)]],
        isToken: [isToken]
      });
    }
  }

  buildPaymentCardExists() {
    if (this.clientExists) {
      this.payment.get('number').setValue( '********');
      this.payment.get('exp').disable();
      this.payment.get('cvv').disable();
    } else {
      this.payment.get('number').setValue('');
      this.payment.get('exp').enable();
      this.payment.get('cvv').enable();
    }


    // const validateClientExists = { 'הוט': 4, 'פרטנר': 6, 'פלאפון': 4, 'הוט מובייל': 4};
    // const cardLen = validateClientExists[this.company];
    // this.paymentCardExists = this.formBuilder.group({
    //   card_number: [{value: null, disabled: true},
    //     [Validators.required, Validators.minLength(cardLen), Validators.maxLength(cardLen)]],
    //   exist_phone: [{value: null, disabled: true},
    //     [Validators.required, Validators.minLength(10), Validators.maxLength(10)]]
    // });
    // if (this.clientExists && this.validateClientExists.includes(this.company)) {
    //   this.paymentCardExists.get('card_number').enable();
    //   if (this.company === 'הוט מובייל') {
    //     this.paymentCardExists.get('exist_phone').enable();
    //   }

  }

  buildClient(client?) {
    const clientIdValidations = [Validators.required, Validators.minLength(9),
      Validators.maxLength(9), validID];
    // if (isID === true || isID === undefined) {
    //   clientIdValidations.push(validID);
    // }
    let identity_type = 'ת״ז';
    let client_id = '';
    let first_name = '';
    let last_name = '';
    let address = '';
    let house = '';
    let flat = '';
    let city = '';
    let phone = '';
    let email = '';
    let track_user_id = '';
    let track_user_4digits = '';
    if (client !== undefined) {
      identity_type = client.identity_type;
      client_id = client.client_id;
      first_name = client.first_name;
      // first_name = `${client.first_name} ${client.last_name}`;
      last_name = client.last_name;
      address = client.address;
      house = client.house;
      flat = client.flat;
      city = client.city;
      phone = client.phone;
      email = client.email;
      track_user_id = client.track_user_id;
      track_user_4digits = client.track_user_4digits;
    }
    this.client = this.formBuilder.group({
        identity_type: [identity_type],
        client_id: [client_id, clientIdValidations],
        first_name: [first_name, [Validators.required, validFullName]],
        last_name: [last_name, [Validators.minLength(2)]],
        address: [address, [Validators.required, Validators.minLength(2), Validators.pattern(/^([^0-9]*)$/)]],
        house: [house, Validators.required],
        flat: [flat],
        city: [city, [Validators.required, Validators.minLength(2)]],
        phone: [phone, [Validators.required, Validators.minLength(9), Validators.maxLength(10), Validators.pattern(/^05\d+/)]],
        // phone: [phone, [Validators.required, Validators.minLength(9), Validators.maxLength(10)]],
        email: [email, Validators.email],
        track_user_id: [track_user_id, [Validators.minLength(9), Validators.maxLength(10)]],
        track_user_4digits: [track_user_4digits],
      }
    );
    this.client.get('identity_type').valueChanges.subscribe(value => {
      const clientIdO = this.client.get('client_id');
      clientIdO.clearValidators();
      if (value === 'תז') {
        clientIdO.setValidators([
          Validators.required, Validators.minLength(9), Validators.maxLength(9), validID]
        );
      } else {
        clientIdO.setValidators([Validators.required]);
      }
      clientIdO.updateValueAndValidity();
    });
    this.client.get('client_id').valueChanges.subscribe(value => {
      if (value.length === 9 && this.snapshotClientID !== value) {
        this.snapshotClientID = value;
        if (this.track.company === 'הוט מובייל') {
          // TODO hot_check_client_exists validation toke a lot of time
          // if (!this.client.value.track_user_id && !this.client.value.track_user_id) {
          //   this.searchClientTrackIDRRequired = true;
          //   this.messageClientTrackIDRRequired = '';
          //   this.requests.get('/hot_check_client_exists/' + value).subscribe(clientExists => {
          //     this.clientTrackIDRRequired = clientExists === true;
          //     this.searchClientTrackIDRRequired = false;
          //   }, (e) => {
          //     this.clientTrackIDRRequired = false;
          //     this.searchClientTrackIDRRequired = false;
          //     this.messageClientTrackIDRRequired = e.error;
          //   });
          // }
        }
      }
    });
    // if (isID !== undefined) {
    //   this.client.markAllAsTouched();
    // }
  }

  validClientTrackIDRRequired() {
    return !((this.track || {}).company === 'הוט מובייל' && this.clientTrackIDRRequired === true &&
      (!this.client.value.track_user_id || !this.client.value.track_user_4digits));
  }


  setClient(event) {
    this.requests.getClient(event.option.value).subscribe((client: any) => {
      if (!('house' in client)) {
        client['house'] = 0;
      }
      if (!('flat' in client)) {
        client['flat'] = 0;
      }
      if (!('identity_type' in client)) {
        client['identity_type'] = 'תז';
      }
      this.client.markAllAsTouched();
      const copied = {...client};

      this.buildPayment(copied.payment);
      delete client['payment'];
      client['first_name'] = `${client['first_name']} ${client['last_name']}`;
      if (!this.client.value.track_user_id) {
        client.track_user_id = '';
      }
      if (!this.client.value.track_user_4digits) {
        client.track_user_4digits = '';
      }
      this.client.setValue(client);
    });
  }

  // setClient(event) {
  //   console.log('setClient', event)
  //   const copied = {...event};
  //
  //   this.buildPayment(copied.payment);
  //   delete event['payment'];
  //   if (!this.client.value.track_user_id) {
  //     event.track_user_id = '';
  //   }
  //   if (!this.client.value.track_user_4digits) {
  //     event.track_user_4digits = '';
  //   }
  //   this.client.setValue(event);
  // }

  onClientChange(event) {
    this.setClient(event);
  }

  onPaymentChange(event) {
    this.buildPayment(event);
  }

  openPdf() {
    this.dialog.open(PdfDialogComponent, {height: '80%'});
  }

  openSignature(event) {
    if (event.checked) {
      const d = this.dialog.open(CanvasDialogComponent, {data: {client: this.client}});
      d.afterClosed().subscribe(result => {
        if (result) {
          this.signature = result.toDataURL();
        } else {
          // TODO set to unchecked
          this.signature = undefined;
        }
      });
    } else {
      this.signature = undefined;
    }
  }

  send() {
    if (this.track && this.track._id && typeof this.track._id === 'object' && '$oid' in this.track._id) {
      this.track._id = this.track._id['$oid'];
    }
    if (this.sale) {
      const body: any = {
        bonus: this.bonus,
        client: this.client.value,
        payment: this.payment.value,
        lines: this.liens,
        track: this.track,
        signature: this.signature ? this.signature : '',
        client_exists: this.clientExists || false,
        comment: this.comment || '',
        self_connect: this.self_connect,
        status: 0,
        user: this.user
      };
      if (this.newDate && this.date.toDateString() !== this.newDate.toDateString()) {
        this.newDate.setHours(this.date.getHours(), this.date.getMinutes(),
          this.date.getSeconds(), this.date.getMilliseconds());
        body.date = JSON.stringify(this.newDate).replace(/"/g, '');
      }
      this.submitted = true;
      this.requests.post('/api/info/update_sale/' + this.sale, body).subscribe(value => {
        this.submitted = false;
        const dialogRef = this.message('עדכון נישלחה!', 'עדכון נשלחה בהצלחה, אנו נטפל בפנייתך בהקדם.');
        dialogRef.afterClosed().subscribe(() => {
          this.dataService.sales = value;
          this.goBack();
        });
      }, (error) => this.errorResponse(error));
    } else {
      const client = this.client.value;
      const first_and_last_name = client.first_name.split(' ');
      client.first_name = first_and_last_name.slice(0, first_and_last_name.length - 1).join(' ');
      client.last_name = first_and_last_name[first_and_last_name.length - 1];
      const body = {
        bonus: this.bonus,
        client: this.client.value,
        payment: this.payment.value,
        lines: this.liens,
        track: this.track,
        signature: this.signature ? this.signature : '',
        client_exists: this.clientExists || false,
        comment: this.comment || '',
        self_connect: this.self_connect,
        user: this.user
      };
      this.submitted = true;
      this.requests.post('/api/info/sales', body).subscribe(value => {
        this.submitted = false;
        const dialogRef = this.message('המכירה נשלחה!', 'המכירה נשלחה בהצלחה, אנו נטפל בפנייתך בהקדם.');
        dialogRef.afterClosed().subscribe(() => {
          this.dataService.sales = value;
          this.router.navigate(['/']);
        });
      }, (error) => this.errorResponse(error));
    }
  }

  message(title, content) {
    return this.dialog.open(MessageDialogComponent, {
      data: {title, content}
    });
  }

  errorResponse(e) {
    this.submitted = false;
    const error =  e.error.includes('invalid credit card') ? 'פרטי הכרטיס שגויים' :  e.error;
    this.message('נכשל!', 'נכשל, עם ההודעה: ' + error);
  }

  validLines() {
    return this.liens.every(x => (x.sim && x.sim.length > 17 && this.liens.filter(l => l.sim === x.sim).length < 2) &&
        (x.phone && (x.phone === 'חדש' || (x.phone.length === 10 && x.phone.startsWith('05')))));
  }

  consoleChange($event: MatDatepickerInputEvent<any>) {
    this.newDate = $event.value;
  }
}
