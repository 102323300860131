import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.authService.getToken();
    if (token) {
      console.log('[AuthGuard] True');
      return true;
    } else {
      console.log('[AuthGuard] False');
      if (state.url.includes('admin')) {
        this.router.navigate(['/login_admin']);
      } else {
        this.router.navigate(['/login']);
      }
      return false;
    }
  }

}


@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.authService.getToken();
    // if (token && this.authService.decodeToken().sub.rule <= 5) {
    if (token && this.authService.decodeToken().sub.is_admin) {
      console.log('[AuthAdminGuard] True');
      return true;
    } else {
      console.log('[AuthAdminGuard] False');
      this.router.navigate(['/']);
      return false;
    }
  }

}

@Injectable()
export class ExitGuard {

  constructor(private authService: AuthService) {
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.authService.tokenExpired();
  }

}
