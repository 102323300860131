import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

function is_numeric(str) {
  return /^\d+( ש״ח)?$/.test(str);
}

@Component({
  selector: 'app-bonus',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.css']
})
export class BonusComponent implements OnInit {
  @Input() bonus: any;
  @Output() bonusChange: EventEmitter<any> = new EventEmitter<any>();
  bonusOption: number;


  constructor() {
  }

  ngOnInit() {
    if (!this.bonus || this.bonus.text === '') {
      this.bonusOption = 1;
    } else {
      if (is_numeric(this.bonus.text)) {
        this.bonusOption = 2;
      } else {
        this.bonusOption = 3;
      }
    }
  }

  setBonusOption(value) {
    this.bonusOption = value;
    this.setBouns('');
  }

  setBouns(value: any) {
    if (!this.bonus) {
      this.bonus = {};
    }
    this.bonus.text = value;
    this.bonusChange.emit(this.bonus);
  }
}
