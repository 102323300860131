import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WarnningDialogComponent} from '../warnning-dialog/warnning-dialog.component';
import {DataService} from '../../data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestsService} from '../../requests.service';
import {DialogSaleComponent} from '../dialog-sale/dialog-sale.component';
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-min-sale-card',
  templateUrl: './min-sale-card.component.html',
  styleUrls: ['./min-sale-card.component.css']
})
export class MinSaleCardComponent implements OnInit {

  @Input() sale;
  @Output() saleChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() payment;
  @Input() edit;
  @Input() delete;
  @Input() billing;

  ifRepeatedMobility;

  constructor(public dataService: DataService, private dialog: MatDialog, private router: Router,
              private requests: RequestsService, private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    const finder = (this.dataService.companies.find(x => x.name === this.sale.track.company) || {});
    if (this.sale.track.kosher) {
      this.ifRepeatedMobility = !!finder.repeated_mobility_kosher_alias;
    } else {
      this.ifRepeatedMobility = !!finder.repeated_mobility_alias;
    }
  }

  editSale() {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'עדכון פרטי מכירה',
        content: 'האם אתה בטוח שברצונך לעדכן את פרטי המכירה?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.router.navigate(['/new_sale', {
          sale: this.sale._id['$oid']
        }]);
      }
    });
  }

  openDialogSale() {
    this.dialog.open(DialogSaleComponent, {
      data: {
        sale: this.sale, payment: this.payment, edit: this.edit, billing: this.billing
      }, height: '80%', width: '80%'
    });
  }

  deleteSale() {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'מחיקת מכירה',
        content: 'האם אתה בטוח שברצונך למחוק מכירה זו?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.sale.status = 2;
        this.requests.post('/api/info/update_sale/' + this.sale._id['$oid'],
          {status: 2}).subscribe(value => {
          this.dataService.sales = value;
          this.saleChange.emit(this.sale);
        });
      }
    });
  }


  repeatedMobility() {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'ניוד חוזר',
        content: 'האם אתה בטוח שברצונך לשלוח לניוד חוזר?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        const body = {
          first_name: this.sale.client.first_name,
          last_name: this.sale.client.last_name,
          client_id: this.sale.client.client_id,
          company: this.sale.track.company,
          last_payment: this.sale.payment.type === 'bankAccount' ? this.sale.payment.number :
            this.sale.payment.token.CreditCardNumber.toString().slice(-4),
          lines: this.sale.lines,
          comment: ''
        };
        this.requests.post('/repeated_mobility', body).subscribe(() => {
          this.snackBar.open('נשלח בהצלחה!', null, {duration: 10000, direction: 'rtl'});
        }, () => {
          this.snackBar.open('לצערנו לא הצלחנו לשלוח!', null, {duration: 10000, direction: 'rtl'});
        });
      }
    });
  }

  anySaleIsShipping() {
    return this.sale.lines.filter(x => x.sim === '000000000000000000').length > 0;
  }

}
