import {Component, OnInit} from '@angular/core';
import {RequestsService} from '../requests.service';
import {DataService} from '../data.service';

@Component({
  selector: 'app-admin',
  template: '<router-outlet></router-outlet>',
})
export class AdminComponent implements OnInit {

  constructor(private requests: RequestsService, private dataService: DataService) {
  }

  ngOnInit() {
    this.getUsers();
    this.getCompanies();
  }

  getCompanies() {
    // this.requests.get('/get_companies').subscribe(value => this.dataService.companies = value);
    this.requests.get('/api/info/get_companies').subscribe(value => this.dataService.companies = value);
  }

  getUsers() {
    this.requests.get('/admin/users').subscribe(value => this.dataService.users = value);
  }

}
