import {Component, Inject} from '@angular/core';
import {WarnningDialogComponent} from '../warnning-dialog/warnning-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-track-dialog',
  template: '<app-track-card [track]="data.track"></app-track-card>',
})
export class TrackDialogComponent {
  constructor(public dialogRef: MatDialogRef<WarnningDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
  }


}
