import {Component, Input, OnInit, Output} from '@angular/core';
import {DataService} from '../../data.service';
import {RequestsService} from '../../requests.service';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.css']
})
export class UserCardComponent implements OnInit {
  @Input() edit;
  @Input() user;

  constructor(public dataService: DataService, private requests: RequestsService) {
  }

  ngOnInit() {
    if (!this.dataService.companies) {
      this.requests.get('/admin/companies').subscribe((value: any) => this.dataService.companies = value);
    }
  }

}
