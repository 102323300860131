import {Component, OnInit, ViewChild} from '@angular/core';
import {RequestsService} from '../../requests.service';
import {DataService} from '../../data.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-admin-service',
  templateUrl: './admin-service.component.html',
  styleUrls: ['./admin-service.component.css']
})
export class AdminServiceComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  sims;
  newList;
  oldList;
  newSims;
  users = [];
  companies = [];
  filter = {key: null, value: null};
  simsColumns: string[] = ['kosher', 'user', 'company', 'request_date', 'count', 'delivered_count', 'done_date', 'comment', 'save',
    'delete', 'active_count_sims'];
  oldSimsColumns: string[] = ['kosher', 'user', 'company', 'request_date', 'count', 'delivered_count', 'done_date',
    'comment'];

  constructor(private request: RequestsService, public dataService: DataService, private formBuilder: UntypedFormBuilder,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.newSims = this.formBuilder.group({
      company: [null, Validators.required],
      count: [null, [Validators.required, Validators.min(1)]],
      kosher: [false],
      username: [null, Validators.required],
    });
    this.getNewSims();
  }

  setFilter(key, value) {
    this.filter.key = key;
    this.filter.value = value;

    this.oldList.filter = value;
  }

  buildSims(value) {
    this.sims = value.map(x => {
      const finder = this.dataService.users.find(u => u.username === x.user);
      x.user = finder ? (finder.first_name + ' ' + finder.last_name) : x.user;
      if (!this.users.includes(x.user)) {
        this.users.push(x.user);
      }
      if (!this.companies.includes(x.company)) {
        this.companies.push(x.company);
      }
      x['delivered_count_clickable'] = false;
      x['delivered_count'] = x['delivered_count'] || 0;
      x['comment_clickable'] = false;
      x['request_date'] = new Date(x['request_date']['$date']);
      x['done_date'] = x['done_date'] ? new Date(x['done_date']['$date']) : null;
      return x;
    });
    this.newList = this.sims.filter(x => !x.done_date);
    this.oldList = new MatTableDataSource(this.sims.filter(x => x.done_date).reverse());
    this.oldList.paginator = this.paginator;
  }

  updateNewSims(element) {
    this.request.post('/admin/new_sims',
      {_id: element._id['$oid'], status: 1, comment: element.comment, delivered_count: element.delivered_count})
      .subscribe((value: any[]) => {
        this.buildSims(value);
      });
  }

  deleteNewSims(element) {
    this.request.post('/admin/new_sims',
      {_id: element._id['$oid'], status: 2, comment: element.comment, delivered_count: 0})
      .subscribe((value: any[]) => {
        this.buildSims(value);
      });
  }

  postNewSims() {
    if (this.newSims.valid) {
      this.request.post('/new_sims', this.newSims.value).subscribe((value: any[]) => {
        this.buildSims(value);
      });
    }
  }

  getNewSims() {
    this.request.get('/admin/new_sims').subscribe(value => {
      this.buildSims(value);
    });
  }

  getFile() {
    this.request.post('/get_csv_path', {
      data: this.sims,
      file_name: 'simsRequests',
      type: 'sims'
    }).subscribe((value: any) => {
      window.open(this.request.serverURL + '/get_csv_file/' + value.filename);
    });
  }

  activeCountSims(element) {
    const user = this.dataService.users.find(x => x.first_name + ' ' + x.last_name === element.user).username;
    const sims = this.oldList.data.filter(x => x.company === element.company && x.user === element.user && x.kosher === element.kosher);
    if (sims.length > 0) {
      const doneDate = sims
        .filter(x => x.done_date !== null)
        .sort((a, b) => (a.request_date < b.request_date) ? 1 : ((b.request_date < a.request_date) ? -1 : 0));
      if (doneDate.length > 0) {
        const currentDate = new Date(doneDate[0].done_date);
        const nextDate = new Date();
        // this.request.get('/sales', {
        this.request.get('/api/info/sales', {
          'between_date': currentDate.getTime() + ',' + nextDate.getTime(),
          'track.company': element.company,
          'user': user
        }).subscribe((data: any[]) => {
          let activeCount = 0;
          data = data.filter(x => x.track.kosher === element.kosher && [0, 1, 5].includes(x.status));
          data.forEach(l => activeCount += l.lines.length);
          element.active_count_sims = `בתאריך ${this.datePipe.transform(currentDate, 'dd/MM/yy')}
           סופקו: ${doneDate[0].delivered_count}, חוברו: ${activeCount}, נשארו: ${doneDate[0].delivered_count - activeCount}`;
        });
      }
    }
  }
}
