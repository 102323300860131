import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestsService} from '../requests.service';
import {DataService} from '../data.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['../login/login.component.css']
})
export class NewPasswordComponent implements OnInit {
  password = new UntypedFormControl('', Validators.required);
  repassword = new UntypedFormControl('', Validators.required);

  constructor(private activatedRoute: ActivatedRoute, private requests: RequestsService, private router: Router,
              private snackBar: MatSnackBar, public dataService: DataService) {
  }

  ngOnInit() {
  }

  send() {
    if (this.password.valid && this.repassword.valid && this.password.value === this.repassword.value) {
      this.activatedRoute.queryParams.subscribe(params => {
        const body = {password: this.password.value};
        if (params.token) {
          body['token'] = params.token;
        }
        this.requests.post('/new_password', body).subscribe((value: any) => {
          if (value.status === 'success') {
            const snackBarRef = this.snackBar.open('הסיסמה החדשה עודכנה.', 'הכנס', {duration: 10000, direction: 'rtl'});
            snackBarRef.onAction().subscribe(() => this.router.navigate(['login']));
          }
        });
      });
      // this.auth.login(this.username.value, this.password.value).subscribe(() => this.router.navigate(['']));
    } else {
      this.repassword.setErrors({'repassword': true});
    }
  }

}
