import {Component, OnInit} from '@angular/core';
import {DataService} from '../../data.service';
import {RequestsService} from '../../requests.service';

@Component({
  selector: 'app-user-space',
  templateUrl: './user-space.component.html',
})
export class UserSpaceComponent implements OnInit {
  selected = 0;

  constructor(public dataService: DataService) {
  }

  ngOnInit() {
  }

  nextTab() {
    if (this.selected < this.dataService.companies.length - 1) {
      this.selected++;
    }
  }

  previousTab() {
    if (this.selected > 0) {
      this.selected--;
    }
  }
}
