import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {fromEvent} from 'rxjs';
import {pairwise, switchMap, takeUntil} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-canvas-dialog',
  template: `
    <p class="text-center">אני {{userName}} מאשר בזאת כי במידה ואנתק את הקווים בתוך התקופה שסוכמה <br>המוכר יהיה רשאי לגבות ממני את שווי ההטבה שקיבלתי.</p>
    <h4 mat-dialog-title class="text-center">חתימה</h4>
    <canvas #signature width="300" height="250" id="signature"></canvas>
    <mat-dialog-actions><button mat-stroked-button (click)="close()">אישור</button></mat-dialog-actions>`,
  styleUrls: ['./canvas-dialog.component.css']
})
export class CanvasDialogComponent implements AfterViewInit {
  @ViewChild('signature', {static: false}) signature: ElementRef;
  public context: CanvasRenderingContext2D;
  canvasEl: HTMLCanvasElement;

  userName;

  constructor(public dialogRef: MatDialogRef<CanvasDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
        this.userName = this.data.client.value.first_name;
  }

  close() {
    this.dialogRef.close(this.canvasEl);
  }

  ngAfterViewInit(): void {

    this.canvasEl = this.signature.nativeElement;
    this.context = this.canvasEl.getContext('2d');


    this.canvasEl.width = 300;
    this.canvasEl.height = 250;


    this.context.lineWidth = 3;
    this.context.lineCap = 'round';
    this.context.lineJoin = 'round';

    this.mobileCanvas();
    this.captureEvents();
  }

  mobileCanvas() {
    const canvas = document.getElementById('signature');
    const canvastop = canvas.offsetTop;

    const context = this.context;

    let lastx;
    let lasty;

    function dot(x, y) {
      context.beginPath();
      context.fillStyle = '#000000';
      context.arc(x, y, 1, 0, Math.PI * 2, true);
      context.fill();
      context.stroke();
      context.closePath();
    }

    function line(fromx, fromy, tox, toy) {
      context.beginPath();
      context.moveTo(fromx, fromy);
      context.lineTo(tox, toy);
      context.stroke();
      context.closePath();
    }

    canvas.ontouchstart = function (event) {
      event.preventDefault();

      lastx = event.touches[0].clientX;
      lasty = event.touches[0].clientY - canvastop;

      dot(lastx, lasty);
    };

    canvas.ontouchmove = function (event) {
      event.preventDefault();

      const newx = event.touches[0].clientX;
      const newy = event.touches[0].clientY - canvastop;

      line(lastx, lasty, newx, newy);

      lastx = newx;
      lasty = newy;
    };
  }


  captureEvents() {
    fromEvent(this.canvasEl, 'mousedown')
      .pipe(
        switchMap(() => {
          return fromEvent(this.canvasEl, 'mousemove')
            .pipe(
              takeUntil(fromEvent(this.canvasEl, 'mouseup')),
              takeUntil(fromEvent(this.canvasEl, 'mouseleave')),
              pairwise()
            );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = this.canvasEl.getBoundingClientRect();

        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
    if (!this.context) {
      return;
    }

    this.context.beginPath();

    if (prevPos) {
      this.context.moveTo(prevPos.x, prevPos.y); // from
      this.context.lineTo(currentPos.x, currentPos.y);
      this.context.stroke();
    }
  }
}
