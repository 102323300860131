import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RequestsService} from '../../requests.service';
import {DataService} from '../../data.service';

@Component({
  selector: 'app-sim-release',
  templateUrl: './sim-release.component.html',
  styleUrls: ['./sim-release.component.css']
})
export class SimReleaseComponent implements OnInit {
  group: UntypedFormGroup;
  displayedColumns = ['phone_number', 'company', 'IMEI', 'status', 'created_date', 'comment'];
  dataSource = [];
  statusOptions = ['חדש', 'הצליח', 'נכשל'];
  inprogress = false;

  constructor(private request: RequestsService, private formBuilder: UntypedFormBuilder, public dataService: DataService) {
  }

  ngOnInit() {
    this.get();
    this.group = this.formBuilder.group({
      phone_number: [null, [Validators.required, Validators.minLength(10), Validators.pattern(/^[0-9]*$/)]],
      company: [null, Validators.required],
      IMEI: [null, Validators.required],
    });
  }

  setData(value) {
    this.dataSource = value;
    this.group.reset();
    this.inprogress = false;
  }

  get() {
    this.request.get('/sim_release').subscribe(value => this.setData(value));
  }

  post() {
    if (this.group.valid) {
      this.inprogress = true;
      this.request.post('/sim_release', this.group.value).subscribe(value => this.setData(value), () => this.inprogress = false);
    }
  }
}
