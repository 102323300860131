import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RequestsService} from '../../requests.service';
import {DataService} from '../../data.service';
import {ActivatedRoute} from '@angular/router';

interface Line {
  sim: string;
  phone: string;
  hostNumber: string;
}

@Component({
  selector: 'app-repeated-mobility',
  templateUrl: './repeated-mobility.component.html',
  styleUrls: ['./repeated-mobility.component.css']
})
export class RepeatedMobilityComponent implements OnInit {

  group: UntypedFormGroup;
  lines: Line[] = [];
  update_sim;

  sims;

  constructor(private request: RequestsService, private formBuilder: UntypedFormBuilder, public dataService: DataService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.getSims();
    this.group = this.formBuilder.group({
      first_name: [null, [Validators.required, Validators.minLength(2)]],
      last_name: [null, [Validators.required, Validators.minLength(2)]],
      client_id: [null, [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      company: [null, Validators.required],
      kosher: [false, Validators.required],
      last_payment: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      lines: [[]],
      comment: [null],
    });
    this.addLine();
  }

  getSims() {
    this.request.get('/repeated_mobility').subscribe(value => {
      this.sims = value;
      this.route.params.subscribe(params => {
        if (params.id) {
          this.request.get('/repeated_mobility/' + params.id).subscribe(sim => {
            this.update_sim = sim;
            this.group.get('first_name').setValue(this.update_sim.first_name);
            this.group.get('last_name').setValue(this.update_sim.last_name);
            this.group.get('client_id').setValue(this.update_sim.client_id);
            this.group.get('company').setValue(this.update_sim.company);
            this.group.get('kosher').setValue(this.update_sim.kosher);
            this.group.get('last_payment').setValue(this.update_sim.last_payment);
            // this.group.get('lines').setValue(this.update_sim.lines);
            this.group.get('comment').setValue(this.update_sim.comment);

            this.lines = this.update_sim.lines;
          });
        }
      });
    });
  }

  post() {
    if (this.group.valid && this.simsValid()) {
      this.group.get('lines').setValue(this.lines);
      if (!this.update_sim) {
        this.request.post('/repeated_mobility', this.group.value).subscribe(value => this.sims = value);
      } else {
        const data = this.group.value;
        data.status = 0;
        this.request.post('/update_repeated_mobility/' + this.update_sim._id['$oid'], data).subscribe(value => this.sims = value);
      }
      this.group.reset();
      this.lines = [];
    }
  }

  simsValid() {
    return this.lines.length > 0 && this.lines.every(x => (x.sim && x.phone && x.hostNumber.length === 10));
  }

  addLine() {
    this.lines.push({sim: '', phone: '', hostNumber: ''});
  }

  removeLine(line) {
    const index = this.lines.indexOf(line);
    if (index > -1 && this.lines.length > 1) {
      this.lines.splice(index, 1);
    }
  }

  setCompany($event: any) {
    this.group.get('company').setValue($event);
  }

}
