import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TrackDialogComponent} from '../track-dialog/track-dialog.component';
import {RequestsService} from '../../requests.service';
import {WarnningDialogComponent} from '../warnning-dialog/warnning-dialog.component';
import {DataService} from '../../data.service';
import {Router} from '@angular/router';
import {ClientDialogComponent} from '../client-dialog/client-dialog.component';
import {UserSpaceDialogComponent} from '../user-space-dialog/user-space-dialog.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {AdminBillingDialog} from '../../admin/admin-billing/admin-billing.component';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {AuthService} from '../../auth.service';
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

const robotsName = {'סלקום': 'robot', 'הוט מובייל': 'hot_mobile_robot'};

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-sale-card',
  templateUrl: './sale-card.component.html',
  styleUrls: ['./sale-card.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class SaleCardComponent implements OnInit {
  @Input() sale;
  @Output() saleChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() payment;
  @Input() edit;
  @Input() billing;
  @Input() dialogRef;

  more = false;
  token;
  billingProgress = false;
  semdEmailProgress = false;
  billingRes;
  currentDate: Date;

  user;

  companiesWitRobot = Object.keys(robotsName);

  constructor(public requests: RequestsService, private dialog: MatDialog, private dataService: DataService,
              private router: Router, private snackBar: MatSnackBar, private auth: AuthService) {
  }

  ngOnInit() {
    this.user = this.auth.decodeToken();
    this.sale.lines.forEach(line => line.hostNumber === undefined ? line.hostNumber = '' : true);
    this.currentDate = new Date(this.sale.date['$date']);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sale.lines, event.previousIndex, event.currentIndex);
  }

  toDate(date) {
    const d = new Date(date);
    return [d.getDate(), d.getMonth() + 1, d.getFullYear()].join('/') + ' ' +
      [d.getHours() < 10 ? '0' + d.getHours() : d.getHours(),
        d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()].join(':');
  }

  openLink(company) {
    const c = this.dataService.companies.find(x => x.name === company);
    if (c && c.link) {
      this.dialog.open(UserSpaceDialogComponent, {data: {company: c}});
    }
  }


  openDetailsDialog(track) {
    this.dialog.open(TrackDialogComponent, {
      data: {
        track: track,
      }, maxHeight: '80%'
    });
  }

  openClientDialog(client) {
    this.dialog.open(ClientDialogComponent, {
      data: {
        client: client,
      }, maxHeight: '80%'
    });
  }

  editSale() {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'עדכון פרטי מכירה',
        content: 'האם אתה בטוח שברצונך לעדכן את פרטי המכירה?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        if (this.dialogRef) {
          this.dialogRef.close();
        }
        this.router.navigate(['/new_sale', {
          sale: this.sale._id['$oid']
        }]);
      }
    });
  }

  update_not_self_connect() {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'העברת מכירה למוקד חיבורים',
        content: 'האם אתה בטוח שברצונך להעביר מכירה זו למוקד חיבורים?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.requests.post('/api/info/update_sale/' + this.sale._id['$oid'],
          {self_connect: false}).subscribe(value => {
          this.sale.self_connect = false;
          this.dataService.sales = value;
          this.saleChange.emit(this.sale);
        });
      }
    });
  }

  update(status) {
    const statusList = ['הצליח', 'נכשל', 'שינוי', 'לטיפול מנהל בכיר', 'נשלח'];
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'עדכון מכירה',
        content: 'האם אתה בטוח שברצונך לעדכן את המכירה כ - ' + statusList[status - 1],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.sale.status = status;
        const body: any = {
          status: status,
          comment: this.sale.comment,
          client_num: this.sale.client_num,
          lines: this.sale.lines
        };
        if (this.sale.shipping_number) {
          body.shipping_number = this.sale.shipping_number;
        }
        this.requests.post('/api/info/update_sale/' + this.sale._id['$oid'],
          body).subscribe(value => {
          this.dataService.sales = value;
          this.saleChange.emit(this.sale);
        });
      }
    });
  }

  start_robot(company) {
    this.sale.robot = true;
    this.requests.post('/start_robot/' + robotsName[company], {
      sale_id: this.sale._id['$oid'],
      lines: this.sale.lines
    }).subscribe(value => {
      if (!this.sale.comment) {
        this.sale.comment = '';
      }
      this.sale.comment += JSON.stringify(value);
    }, (error) => {
      console.log(error);
    });
  }

  repeatedMobility() {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'ניוד חוזר',
        content: 'האם אתה בטוח שברצונך לשלוח לניוד חוזר?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        const body = {
          first_name: this.sale.client.first_name,
          last_name: this.sale.client.last_name,
          client_id: this.sale.client.client_id,
          company: this.sale.track.company,
          last_payment: this.sale.payment.token.CreditCardNumber.toString().slice(-4),
          lines: this.sale.lines,
          comment: ''
        };
        this.requests.post('/repeated_mobility', body).subscribe(() => {
          this.snackBar.open('נשלח בהצלחה!', null, {duration: 10000, direction: 'rtl'});
        }, () => {
          this.snackBar.open('לצערנו לא הצלחנו לשלוח!', null, {duration: 10000, direction: 'rtl'});
        });
      }
    });
  }

  setMore() {
    this.more = !this.more;
    if (this.more && !this.token && this.payment) {
      this.requests.get('/convert_to_cc/' + this.sale._id['$oid']).subscribe((value: any) => {
        if (value.StatusCode === '000') {
          this.token = value.ResultData;
        }
      });
    }
  }

  setBilling() {
    const dialogRef = this.dialog.open(AdminBillingDialog, {
      data: {price: 100}
    });
    dialogRef.afterClosed().subscribe(price => {
      if (price) {
        this.billingProgress = true;
        this.requests.get('/admin/billing/' + this.sale._id['$oid'], {price}).subscribe(value => {
          this.billingProgress = false;
          this.billingRes = value;
        }, e => {
          console.log(e);
          this.billingProgress = false;
        });
      }
    });

  }

  sendToManager() {
    this.semdEmailProgress = true;
    this.requests.get('/send_email_alias/' + this.sale._id['$oid'], {reason: this.sale.comment}).subscribe((value: any) => {
      this.semdEmailProgress = false;
      this.dialog.open(MessageDialogComponent, {data: {title: 'שליחת מייל למנהל', content: value.message}});
    });
  }

  message(title, content) {
    return this.dialog.open(MessageDialogComponent, {
      data: {title, content}
    });
  }

  errorResponse(e) {
    console.log(e);
    this.message('ניכשל!', 'ניכשל, עם ההודעה: ' + e.error);
  }

  updateDate(event: MatDatepickerInputEvent<any>) {
    const date = event.value.utc().toDate();
    date.setHours(this.currentDate.getHours(), this.currentDate.getMinutes(),
      this.currentDate.getSeconds(), this.currentDate.getMilliseconds());
    const body = {date: JSON.stringify(date).replace(/"/g, '')};
    this.requests.post('/api/info/update_sale/' + this.sale._id['$oid'], body).subscribe(_ => {
      const dialogRef = this.message('עדכון נישלח!', 'התאריך שונה בהצלחה');
      dialogRef.afterClosed().subscribe(() => {

      });
    }, (error) => this.errorResponse(error));
  }

  saveBonus(status?) {
    if (status) {
      this.sale.bonus.status = status;
    }
    const body = {bonus: this.sale.bonus};
    this.requests.post('/api/info/update_sale/' + this.sale._id['$oid'], body).subscribe(_ => {
      const dialogRef = this.message('עדכון נישלח!', 'עדכון הטבה');
      dialogRef.afterClosed().subscribe(() => {

      });
    }, (error) => this.errorResponse(error));

  }

  saveShippingNumber() {
    const body = {shipping_number: this.sale.shipping_number};
    this.requests.post('/api/info/update_sale/' + this.sale._id['$oid'], body).subscribe(_ => {
      const dialogRef = this.message('עדכון נישלח!', 'עדכון מספר הזמנה');
      dialogRef.afterClosed().subscribe(() => {

      });
    }, (error) => this.errorResponse(error));

  }

  saveTrackUser() {
    const body = {
      'client.track_user_id': this.sale.client.track_user_id,
      'client.track_user_4digits': this.sale.client.track_user_4digits,
    };
    this.requests.post('/api/info/update_sale/' + this.sale._id['$oid'], body).subscribe(_ => {
      const dialogRef = this.message('עדכון נישלח!', 'עדכון מספר מנוי');
      dialogRef.afterClosed().subscribe(() => {

      });
    }, (error) => this.errorResponse(error));

  }

  anySaleIsShipping() {
    return this.sale.lines.filter(x => x.sim === '000000000000000000').length > 0;
  }
}
