import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestsService} from "../../requests.service";

@Component({
  selector: 'app-admin-operation',
  templateUrl: './admin-operation.component.html',
  styleUrls: ['./admin-operation.component.css']
})
export class AdminOperationComponent implements OnInit {

  activeLink = 'sales';
  navLinks = [
    {path: 'sales', label: 'איקטובים'},
    {path: 'service', label: 'הזמנות סימים'},
    {path: 'change_sims', label: 'החלפות סימים'},
    {path: 'repeated_mobility', label: 'ניוד חוזר'},
    {path: 'sim_release', label: 'שחרור סימים'},
    {path: 'billing', label: 'חיוב לקוחות'},
  ];

  badge: any = {sales: 0, change_sims: 0, repeated_mobility: 0};

  constructor(public router: Router, private route: ActivatedRoute, private requests: RequestsService) {
  }

  ngOnInit() {
    this.activeLink = this.route.snapshot.children[0].url[0].path;
    this.requests.get('/admin/new_change_sims_count').subscribe((value: any) => this.badge.change_sims = value.count);
    this.requests.get('/admin/new_sales_count').subscribe((value: any) => this.badge.sales = value.count);
    this.requests.get('/admin/new_repeated_mobility_count').subscribe((value: any) => this.badge.repeated_mobility = value.count);
    this.requests.get('/admin/new_sim_release_count').subscribe((value: any) => this.badge.sim_release = value.count);
  }

  navigate(link) {
    this.activeLink = link.path;
    this.router.navigate([link.path], {relativeTo: this.route});
  }

}
