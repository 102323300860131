import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WarnningDialogComponent} from '../warnning-dialog/warnning-dialog.component';
import {RequestsService} from '../../requests.service';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-change-sim-card',
  templateUrl: './change-sim-card.component.html',
  styleUrls: ['./change-sim-card.component.css']
})
export class ChangeSimCardComponent implements OnInit {

  @Input() isAdmin;
  @Input() sim;
  @Output() simChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private requests: RequestsService, private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  update(status: number) {
    const statusList = ['הצליח', 'ביטול', 'בהמתנה'];
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'עדכון מכירה',
        content: 'האם אתה בטוח שברצונך לעדכן את המכירה כ - ' + statusList[status - 1],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.sim.status = status;
        this.requests.post('/admin/change_sims',
          {
            comment: this.sim.comment,
            status: status,
            _id: this.sim._id['$oid'],
          }).subscribe(value => {
          this.simChange.emit(value);
        });
      }
    });
  }
}
