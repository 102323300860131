import {Component, Inject, Input, Optional} from '@angular/core';
import {RequestsService} from '../../requests.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-user-space-dialog',
  templateUrl: './user-space-dialog.component.html',
})
export class UserSpaceDialogComponent {

  @Input() company;
  status = {message: null, phone: ''};
  np_port = {message: null, client_id: '', number: '', host_number: ''};

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data, private requests: RequestsService) {
    if (data) {
      this.company = data.company;
    }
  }

  startBarcode() {
    this.requests.get('/cellcom/status?number=' + this.status.phone).subscribe(value => this.status.message = value);
  }

  getNPPort() {
    this.requests.get('/cellcom/np_port', {
      client_id: this.np_port.client_id,
      number: this.np_port.number,
      host_number: this.np_port.host_number,
    }).subscribe(value => this.np_port.message = value);

  }

}
