import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {DataService} from '../../../data.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {RequestsService} from '../../../requests.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
  @Input() client: UntypedFormGroup;
  @Input() clientTrackIDRRequired: boolean;
  @Output() clientChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  clients;
  clientFilteredOptions: Observable<any[]>;

  citiesFilteredOptions: Observable<any[]>;
  cities;

  addressFilteredOptions: Observable<any[]>;
  address;

  constructor(public dataService: DataService, private requests: RequestsService) {
  }

  ngOnInit() {
    this.getAllCities();
    this.clientFilteredOptions = this.client.get('client_id').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterClients(value))
      );
    this.addressFilteredOptions = this.client.get('address').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterAddress(value))
      );
  }

  getAllCities() {
    this.requests.get('/api/info/get_all_cities').subscribe(cities => {
      this.cities = cities;
      this.citiesFilteredOptions = this.client.get('city').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterCities(value))
        );
    });
  }

  private _filterClients(value: string): string[] {
    if (value.length < 3) {
      return [];
    }
    const filterValue = value.toString().toLowerCase();

    return this.dataService.clients_ids.filter(option => {
      return option.toString().toLowerCase().includes(filterValue);
    });
  }

  private _filterCities(value: string): string[] {
    const filterValue = value.toString().toLowerCase();

    return this.cities.map(x => {
      return x;
    }).filter(option => {
      return option.name.toString().toLowerCase().includes(filterValue);
      // return option.syn.toString().toLowerCase().includes(filterValue);
    });
  }

  private _filterAddress(value: string): string[] {
    const filterValue = value.toString().toLowerCase();
    if (!this.address) {
      return null;
    }
    return this.address.map(x => {
      return x;
    }).filter(option => {
      return option.street_name.toString().toLowerCase().includes(filterValue);
    });
  }

  setClient(event) {
    this.requests.getClient(event.option.value).subscribe((client: any) => {
      if (!('house' in client)) {
        client['house'] = 0;
      }
      if (!('flat' in client)) {
        client['flat'] = 0;
      }
      if (!('identity_type' in client)) {
        client['identity_type'] = 'תז';
      }
      this.client.markAllAsTouched();
      this.clientChange.emit(client);
    });
  }

  getAddress(event?) {
    const cityName = this.client.controls.city.value;
    const cityObj = this.cities.find(x => x.name.trim() === cityName);
    if (cityObj) {
      this.requests.get('/api/info/get_streets/' + cityObj.code).subscribe(value => this.address = value);
    }
  }

  getErrorMessage(g) {
    const f = this.client.get(g);
    if (g === 'client_id') {
      if (f.hasError('required')) {
        return 'נא להזין תעודת זהות';
      } else if (f.hasError('minlength') || f.hasError('maxlength')) {
        return 'תעודת זהות חייב להכיל 9 ספרות';
      } else if (f.hasError('clientIdInvalid')) {
        return 'התעודת זהות אינה תקינה';
      }
    } else if (g === 'first_name') {
      if (f.hasError('required')) {
        return 'נא להזין שם פרטי ומשפחה';
      } else if (f.hasError('minlength')) {
        return 'נא להזין שם פרטי ומשפחה';
      }
    } else if (g === 'last_name') {
      if (f.hasError('required')) {
        return 'היי, שכחת להכניס שם משפחה :)';
      } else if (f.hasError('minlength')) {
        return 'אף פעם לא ניתקלנו בשם משפחה עם אות אחת... :)';
      }
    } else if (g === 'city') {
      if (f.hasError('required')) {
        return 'נא להזין שם עיר';
      } else if (f.hasError('minlength')) {
        return 'נא להזין שם עיר תקין';
      }
    } else if (g === 'address') {
      if (f.hasError('required')) {
        return 'נא להזין שם רחוב';
      } else if (f.hasError('minlength')) {
        return 'נא להזין שם רחוב תקין';
      } else if (f.hasError('pattern')) {
        return 'נא להזין שם רחוב בלבד';
      }
    } else if (g === 'house') {
      if (f.hasError('required')) {
        return 'נא להזין מספר בית';
      }
    } else if (g === 'phone') {
      if (f.hasError('required')) {
        return 'נא להזין מספר טלפון';
      }else if (f.hasError('pattern')) {
        return 'נא להזין מספר טלפון תקין';
      } else if (f.hasError('minlength') || f.hasError('maxlength')) {
        return 'מספר טלפון חייב להכיל בין 9 ל-10 ספרות';
      }
    } else if (g === 'email') {
      if (f.hasError('email')) {
        return 'כתובת מייל אינה תקינה';
      }
    }
  }
}
