import {Component, Inject} from '@angular/core';
import {ClientDialogComponent} from '../client-dialog/client-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-sale',
  template: '<app-sale-card [dialogRef]="dialogRef" [billing]="data.billing" [payment]="data.payment" [edit]="data.edit" [sale]="data.sale"></app-sale-card>',
})
export class DialogSaleComponent {

  constructor(public dialogRef: MatDialogRef<ClientDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
  }

}
