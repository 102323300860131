import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {SimplePdfViewerModule} from 'simple-pdf-viewer';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {ChartModule} from 'angular-highcharts';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDividerModule} from '@angular/material/divider';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {DragDropModule} from '@angular/cdk/drag-drop';

import {AppComponent} from './app.component';
import {routes} from './app.routes';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './user/home/home.component';
import {AuthAdminGuard, AuthGuard, ExitGuard} from './auth.guard';
import {AuthService} from './auth.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NewSaleComponent} from './user/new-sale/new-sale.component';
import {CompanyComponent} from './user/new-sale/company/company.component';
import {TrackComponent} from './user/new-sale/track/track.component';
import {ClientComponent} from './user/new-sale/client/client.component';
import {CreditCardMaskPipe, DateMaskPipe, PaymentComponent} from './user/new-sale/payment/payment.component';
import {LinesComponent} from './user/new-sale/lines/lines.component';
import {BarecodeScannerLivestreamModule} from 'ngx-barcode-scanner';
import {LoginAdminComponent} from './login-admin/login-admin.component';
import {AdminComponent} from './admin/admin.component';
import {AdminCompaniesComponent} from './admin/admin-companies/admin-companies.component';
import {AdminHomeComponent} from './admin/admin-home/admin-home.component';
import {WarnningDialogComponent} from './utils/warnning-dialog/warnning-dialog.component';
import {AdminTrackComponent} from './admin/admin-track/admin-track.component';
import {
    TrackCardComponent,
    TrackDetailsDialogComponent
} from './utils/track-card/track-card.component';
import {TrackDialogComponent} from './utils/track-dialog/track-dialog.component';
import {TokenInterceptor} from './token-interceptor.service';
import {DataService} from './data.service';
import {UserComponent} from './user/user.component';
import {TracksComponent} from './user/tracks/tracks.component';
import {MySalesComponent} from './user/my-sales/my-sales.component';
import {SaleCardComponent} from './utils/sale-card/sale-card.component';
import {ClientsComponent} from './user/clients/clients.component';
import {ClientCardComponent} from './utils/client-card/client-card.component';
import {AdminClientsComponent} from './admin/admin-clients/admin-clients.component';
import {SearchPipe} from './utils/search.pipe';
import {AdminUsersComponent} from './admin/admin-users/admin-users.component';
import {RegisterComponent} from './register/register.component';
import {UserCardComponent} from './utils/user-card/user-card.component';
import {NewPasswordComponent} from './new-password/new-password.component';
import {AdminSalesComponent} from './admin/admin-sales/admin-sales.component';
import {PdfDialogComponent} from './utils/pdf-dialog/pdf-dialog.component';
import {ClientDialogComponent} from './utils/client-dialog/client-dialog.component';
import {CanvasDialogComponent} from './utils/canvas-dialog/canvas-dialog.component';
import {CompanyCardComponent} from './utils/company-card/company-card.component';
import {UserSpaceDialogComponent} from './utils/user-space-dialog/user-space-dialog.component';
import {UserSpaceComponent} from './user/user-space/user-space.component';
import {SettingComponent} from './user/setting/setting.component';
import {MinSaleCardComponent} from './utils/min-sale-card/min-sale-card.component';
import {DialogSaleComponent} from './utils/dialog-sale/dialog-sale.component';
import {UserServiceComponent} from './user/user-service/user-service.component';
import {AdminServiceComponent} from './admin/admin-service/admin-service.component';
import {ChangeSimsComponent} from './user/change-sims/change-sims.component';
import {ChangeSimCardComponent} from './utils/change-sim-card/change-sim-card.component';
import {AdminChangeSimsComponent} from './admin/admin-change-sims/admin-change-sims.component';
import {MinChangeSimCardComponent} from './utils/min-change-sim-card/min-change-sim-card.component';
import {DialogChangeSimComponent} from './utils/dialog-change-sim/dialog-change-sim.component';
import {AdminRepeatedMobilityComponent} from './admin/admin-repeated-mobility/admin-repeated-mobility.component';
import {RepeatedMobilityCardComponent} from './utils/repeated-mobility-card/repeated-mobility-card.component';
import {
    MinRepeatedMobilityCardComponent
} from './utils/min-repeated-mobility-card/min-repeated-mobility-card.component';
import {OperationSimComponent} from './user/operation-sim/operation-sim.component';
import {RepeatedMobilityComponent} from './user/repeated-mobility/repeated-mobility.component';
import {DialogRepeatedMobilityComponent} from './utils/dialog-repeated-mobility/dialog-repeated-mobility.component';
import {RenovationsComponent} from './user/renovations/renovations.component';
import {MessageDialogComponent} from './utils/message-dialog/message-dialog.component';
import {AdminBillingComponent, AdminBillingDialog} from './admin/admin-billing/admin-billing.component';
import {DatePipe} from '@angular/common';
import {SimReleaseComponent} from './user/sim-release/sim-release.component';
import {
    AdminSimReleaseComponent,
    AdminSimReleaseTableComponent
} from './admin/admin-sim-release/admin-sim-release.component';
import {AdminOperationComponent} from './admin/admin-operation/admin-operation.component';
import {BonusComponent} from './user/new-sale/bonus/bonus.component';
import {VerifyComponent} from './verify/verify.component';
import {ErrorInterceptor} from './error-interceptor.service';
import {LabComponent} from './user/lab/lab.component';
import {AdminLibComponent} from './admin/admin-lib/admin-lib.component';
import {MatNativeDateModule} from '@angular/material/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatButtonModule} from '@angular/material/button';
// import {LayoutModule} from "./layout/layout.module";

export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'pinch': {enable: false},
        'rotate': {enable: false}
    };
    options = {cssProps: {userSelect: true}};
}


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        NewSaleComponent,
        CompanyComponent,
        TrackComponent,
        ClientComponent,
        PaymentComponent,
        LinesComponent,
        CreditCardMaskPipe,
        DateMaskPipe,
        LoginAdminComponent,
        AdminComponent,
        AdminCompaniesComponent,
        AdminHomeComponent,
        WarnningDialogComponent,
        AdminTrackComponent,
        TrackCardComponent,
        TrackDialogComponent,
        UserComponent,
        TracksComponent,
        MySalesComponent,
        SaleCardComponent,
        ClientsComponent,
        ClientCardComponent,
        AdminClientsComponent,
        SearchPipe,
        AdminUsersComponent,
        RegisterComponent,
        UserCardComponent,
        NewPasswordComponent,
        AdminSalesComponent,
        PdfDialogComponent,
        ClientDialogComponent,
        CanvasDialogComponent,
        CompanyCardComponent,
        UserSpaceDialogComponent,
        UserSpaceComponent,
        SettingComponent,
        MinSaleCardComponent,
        DialogSaleComponent,
        UserServiceComponent,
        AdminServiceComponent,
        ChangeSimsComponent,
        ChangeSimCardComponent,
        AdminChangeSimsComponent,
        MinChangeSimCardComponent,
        DialogChangeSimComponent,
        AdminRepeatedMobilityComponent,
        RepeatedMobilityCardComponent,
        MinRepeatedMobilityCardComponent,
        OperationSimComponent,
        RepeatedMobilityComponent,
        DialogRepeatedMobilityComponent,
        RenovationsComponent,
        MessageDialogComponent,
        AdminBillingComponent,
        AdminBillingDialog,
        SimReleaseComponent,
        AdminSimReleaseComponent,
        AdminSimReleaseTableComponent,
        AdminOperationComponent,
        BonusComponent,
        VerifyComponent,
        LabComponent,
        AdminLibComponent,
        TrackDetailsDialogComponent
    ],
    // providers: [DatePipe, DataService, AuthService, AuthGuard, {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptor,
    //   multi: true
    // }, {
    //   provide: HAMMER_GESTURE_CONFIG,
    //   useClass: MyHammerConfig
    // }],
    providers: [DatePipe, DataService, AuthGuard, AuthService, ExitGuard, AuthAdminGuard,
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig}],
    imports: [
        BrowserModule, HttpClientModule, RouterModule.forRoot(routes), NgbPaginationModule, NgbAlertModule, BrowserAnimationsModule,
        MatButtonModule, MatToolbarModule, MatFormFieldModule, MatInputModule, MatIconModule, FormsModule, ReactiveFormsModule,
        MatGridListModule, MatCardModule, MatStepperModule, MatRadioModule, MatTabsModule, MatDatepickerModule,
        BarecodeScannerLivestreamModule,
        MatNativeDateModule, MatListModule, MatDialogModule, MatSlideToggleModule, MatProgressSpinnerModule, MatButtonToggleModule,
        MatSnackBarModule, MatAutocompleteModule, SimplePdfViewerModule, MatTableModule, MatMenuModule, MatCheckboxModule, MatSidenavModule,
        MatDividerModule, AngularEditorModule, MatTooltipModule, MatBadgeModule, ChartModule, MatSelectModule, MatProgressBarModule,
        DragDropModule, MatExpansionModule, MatPaginatorModule, MatSortModule
        // LayoutModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
