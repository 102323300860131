import {Component, OnInit} from '@angular/core';
import {RequestsService} from '../../requests.service';
import {AuthService} from '../../auth.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['../../user/home/home.component.css']
})
export class AdminHomeComponent implements OnInit {
  admin;
  newChangeSimCount = 0;
  newRepeatedMobilityCount = 0;
  newSalesCount = 0;

  constructor(private requests: RequestsService, public auth: AuthService) {
  }

  ngOnInit() {
    this.requests.get('/admin/get_admin').subscribe((value: any) => this.admin = value);
  }


}
