import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {RequestsService} from '../requests.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginGroup: FormGroup;
  forget = false;
  message = 'הכנס את כתובת האימייל שלך ולחץ שלח.';
  error: string;

  constructor(private auth: AuthService, private router: Router, private formBuilder: FormBuilder,
              private req: RequestsService) {
  }

  ngOnInit() {
    this.loginGroup = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern(/[\S]/), Validators.email]],
      password: ['', [Validators.required, Validators.pattern(/[\S]/)]]
    });
    // this.loginGroup.valueChanges.subscribe(ch => {
    //   this.error = null;
    // });
  }

  newPassword() {
    if (this.loginGroup.get('username').valid) {
      this.req.get('/new_password?username=' + this.loginGroup.value.username).subscribe(value => {
        this.message = 'הסיסמה נשלחה לכתובת האימייל שהיזנת.';
      });
    }
  }

  login() {
    if (this.loginGroup.valid) {
      this.auth.login(this.loginGroup.value.username, this.loginGroup.value.password).subscribe(() => {
        if (this.auth.getToken()) {
          this.router.navigate(['']);
          this.router.navigate(['/']);
        } else {
          this.auth.setCookie('toEmail', this.loginGroup.value.username);
          // document.cookie.
          this.router.navigate(['/verify']);
        }
      }, (error) => {
        this.error = error.error.msg ? error.error.msg : null;
        this.loginGroup.get('username').setValue(null);
        this.loginGroup.get('password').setValue(null);
        // const g = this.loginGroup.get('password');
        // if (error.error.msg === 'Bad username or password') {
        //   g.setErrors({'password': true});
        // } else if (error.error.msg === 'Max retires') {
        //   g.setErrors({'retries': true});
        // } else {
        //   g.setErrors({'other': true});
        // }
      });
    }
  }
}
