import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth.service';
import {DataService} from '../../data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  mySales;
  waitingSales;
  carousel = [
    {src: 'slide1.png', href: '/lab'},
    // {src: 'slide2.png', href: 'https://www.dayan-tikshoret.co.il/my-account/'},
  ];
  carouselActive = 1;

  constructor(private auth: AuthService, public dataService: DataService) {
  }

  ngOnInit() {
    const now = new Date();
    const current = new Date(now.getFullYear(), now.getMonth(), 1);
    const after = new Date(now.getFullYear(), now.getMonth(), 1);
    after.setMonth(after.getMonth() + 1);
    this.mySales = this.dataService.sales ? this.dataService.sales.filter(x => new Date(x.date['$date']) >= current &&
      new Date(x.date['$date']) < after && (x.status === 1 || x.status === 2) && x.user === this.dataService.user.username) : [];
    this.setWaitingSales();
  }

  setWaitingSales() {
    this.waitingSales = this.dataService.sales ? this.dataService.sales.filter(
        x => x.user === this.dataService.user.username && x.status === 3) : [];
  }


  logout() {
    this.dataService.sales = null;
    this.dataService.tracks = null;
    this.dataService.companies = null;
    this.dataService.user = null;
    this.auth.logout();
  }

  previous() {
    if (this.carouselActive === 0) {
      this.carouselActive = this.carousel.length - 1;
    } else {
      this.carouselActive -= 1;
    }
  }

  next() {
    if (this.carouselActive === (this.carousel.length - 1)) {
      this.carouselActive = 0;
    } else {
      this.carouselActive += 1;
    }
  }

  goTo(href: string) {
    window.open(href, '_blank');
  }
}
