import {Component, OnInit} from '@angular/core';
import {RequestsService} from '../../requests.service';
import {WarnningDialogComponent} from '../../utils/warnning-dialog/warnning-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-admin-companies',
  templateUrl: './admin-companies.component.html',
  styleUrls: ['./admin-companies.component.css']
})
export class AdminCompaniesComponent implements OnInit {
  companies = [];
  uploads = [];
  new_company;

  constructor(private requests: RequestsService, private dialog: MatDialog) {
  }


  ngOnInit() {
    this.resetNewCompany();
    this.getCompanies();
  }

  // resetNewCompany() {
  //   this.new_company = {
  //     edit: true,
  //     name: '',
  //     file: '',
  //     link: '',
  //     sale_mail_alias: null,
  //     payment_bank_account: null,
  //     new_sale: null,
  //     change_sims_mail_alias: null,
  //     sim_release_mail_alias: null,
  //     repeated_mobility: null
  //   };
  // }
  resetNewCompany() {
    this.new_company = {
      edit: true,
      name: '',
      file: '',
      link: '',
      manager_sales_alias: null,
      all_sales_alias: null,
      direct_debit_alias: null,
      exists_client_alias: null,
      passport_alias: null,
      private_company_alias: null,
      sim_exchange_manager_alias: null,
      sim_release_manager_alias: null,
      repeated_mobility_alias: null,
      repeated_mobility_kosher_alias: null,
      approve_call_number_alias: null,
      direct_debit_commission_alias: null
    };
  }

  getCompanies() {
    this.requests.get('/admin/companies').subscribe((value: any) => this.companies = value);
  }

  loadURL(event, company) {
    if (event.target.files && event.target.files.item(0)) {
      company.tmp = company.file;
      const file = event.target.files.item(0);
      const data: any = {
        name: company.name,
        file: file,
        link: company.link,
      };
      Object.keys(company).filter(x => x.endsWith('_alias')).forEach(x => {
        data[x] = company[x];
      });
      this.uploads.push(data);
      const reader = new FileReader();
      reader.onload = e => company.file = reader.result;
      reader.readAsDataURL(file);
    }
  }

  delete(company_id) {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'מחק חברה',
        content: 'האם אתה בטוח שברצונך למחוק את ' + company_id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.requests.delete('/admin/companies/' + company_id).subscribe((value: any) => this.companies = value);
      }
    });
  }

  save() {
    this.requests.postFiles('/admin/companies', this.uploads).subscribe((value: any) => {
      this.companies = value;
      this.uploads = [];
      this.resetNewCompany();
    });
  }

  save_one(company) {
    const body: any = {
      name: company.name,
      link: company.link,
    };
    Object.keys(company).filter(x => x.endsWith('_alias')).forEach(x => {
      body[x] = company[x];
    });
    this.requests.post('/admin/companies/' + company._id, body).subscribe(() => {
      company.edit = false;
    });
  }

  edit(company) {
    Object.keys(company).filter(x => x.endsWith('_alias')).forEach(x => {
      if (!company[x]) {
        company[x] = null;
      }
    });
    // if (!company.new_sale) {
    //   company.new_sale = null;
    // }
    // if (!company.sale_mail_alias) {
    //   company.sale_mail_alias = null;
    //   company.payment_bank_account = null;
    // }
    // if (!company.change_sims_mail_alias) {
    //   company.change_sims_mail_alias = null;
    // }
    // if (!company.sim_release_mail_alias) {
    //   company.sim_release_mail_alias = null;
    // }
    // if (!company.repeated_mobility) {
    //   company.repeated_mobility = null;
    // }
    company.edit = !company.edit;
  }
}
