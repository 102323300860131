import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-operation-sim',
  templateUrl: './operation-sim.component.html',
  styleUrls: ['./operation-sim.component.css']
})
export class OperationSimComponent implements OnInit {
  activeLink = 'change_sims';
  navLinks = [
    {path: 'user_space', label: 'כניסת סוכנים'},
    {path: 'change_sims', label: 'החלפת סימים'},
    {path: 'repeated_mobility', label: 'ניוד חוזר'},
    {path: 'sim_release', label: 'שחרור סים'},
  ];

  constructor(public router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.activeLink = this.route.snapshot.children[0].url[0].path;
  }

  navigate(link) {
    this.activeLink = link.path;
    this.router.navigate([link.path], {relativeTo: this.route});
  }

}
