import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-warnning-dialog',
    templateUrl: './warnning-dialog.component.html',
})
export class WarnningDialogComponent {

    constructor(public dialogRef: MatDialogRef<WarnningDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    }
}
