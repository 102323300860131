import {Component, OnInit} from '@angular/core';
import {DataService} from '../../data.service';
import {RequestsService} from '../../requests.service';

@Component({
  selector: 'app-my-sales',
  templateUrl: './my-sales.component.html',
  styleUrls: ['./my-sales.component.css']
})
export class MySalesComponent implements OnInit {
  sales;
  waitingSales;
  selfSales;
  date;
  kosher = false;
  search;

  dataSource;
  displayedColumns = ['company', 'sum', 'download'];

  selected = 0;

  constructor(public dataService: DataService, private requests: RequestsService) {
  }

  ngOnInit() {
    this.getData();
  }

  nextTab() {
    if (this.selected < 1) {
      this.selected++;
    }
  }

  previousTab() {
    if (this.selected > 0) {
      this.selected--;
    }
  }

  getTable() {
    const tmp = [];
    this.dataService.companies.map(x => x.name).forEach(x => {
      const sales = this.sales.filter(s => s.status === 1 && s.track.company === x);
      tmp.push({
        company: x, sum: sales.reduce((a, b) => a + b.lines.length, 0),
        download: {data: sales, file_name: 'report', type: 'sale'}
      });
    });
    this.dataSource = tmp;
  }

  getFile(sales) {
    this.requests.post('/get_csv_path', sales).subscribe((value: any) => {
      window.open(this.requests.serverURL + '/get_csv_file/' + value.filename);
    });
  }

  getData() {
    this.currentMonth();
    this.waitingSales = this.dataService.sales.filter(x => x.user === this.dataService.user.username && x.status === 3);
    this.selfSales = this.dataService.sales.filter(x => x.user === this.dataService.user.username && x.status === 0 && x.self_connect);
  }

  currentMonth() {
    this.date = new Date();
    this.filterSuccessOrFailSales();
  }

  addMonth() {
    if (new Date(new Date().getFullYear(), 1) >= this.date) {
      this.date.setMonth(this.date.getMonth() + 1);
      this.filterSuccessOrFailSales();
    }
  }

  lessMonth() {
    const now = new Date();
    const sixMonthBeforeNow = new Date(now).setMonth(now.getMonth() - 5);
    if (sixMonthBeforeNow <= this.date) {
      this.date.setMonth(this.date.getMonth() - 1);
      this.filterSuccessOrFailSales();
    }
  }

  filterSuccessOrFailSales() {
    const current = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    const after = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    after.setMonth(after.getMonth() + 1);
    this.sales = this.dataService.sales.filter(x => x.user === this.dataService.user.username && new Date(x.date['$date']) >= current &&
      new Date(x.date['$date']) < after && x.track.kosher === this.kosher);
    this.getTable();
  }


}
