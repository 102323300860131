import {Component, OnInit} from '@angular/core';
import {RequestsService} from '../../requests.service';
import {DataService} from '../../data.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {WarnningDialogComponent} from '../../utils/warnning-dialog/warnning-dialog.component';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-user-service',
  templateUrl: './user-service.component.html',
  styleUrls: ['./user-service.component.css']
})
export class UserServiceComponent implements OnInit {

  newSims: UntypedFormGroup;
  sims;
  simsColumns: string[] = ['kosher', 'company', 'request_date', 'count', 'delivered_count', 'done_date', 'comment', 'delete'];

  message;
  canActive = false;

  constructor(private request: RequestsService, public dataService: DataService, private formBuilder: UntypedFormBuilder,
              private dialog: MatDialog, private datePipe: DatePipe) {
  }

  setMessage(message) {
    this.message = message;
    this.canActive = true;
  }

  ngOnInit() {
    this.newSims = this.formBuilder.group({
      company: [null, Validators.required],
      count: [null, [Validators.required, Validators.min(1)]],
      kosher: [false],
    });
    this.newSims.get('company').valueChanges.subscribe(value => {
      this.canActive = false;
      const sims = this.sims.filter(x => x.company === value);
      if (sims.length > 0) {
        const doneDate = sims
          .filter(x => x.done_date !== null)
          .sort((a, b) => (a.request_date < b.request_date) ? 1 : ((b.request_date < a.request_date) ? -1 : 0));
        if (doneDate.length > 0) {
          const currentDate = new Date(doneDate[0].done_date);
          const nextDate = new Date();
          this.request.get('/api/info/sales', {
            'between_date': currentDate.getTime() + ',' + nextDate.getTime(),
            'track.company': value
          }).subscribe((data: any[]) => {
            let activeCount = 0;
            data = data.filter(x => [0, 1, 5].includes(x.status));
            data.forEach(l => activeCount += l.lines.length);
            const dateF = this.datePipe.transform(currentDate, 'dd/MM/yy');
            const deliveredCount = doneDate[0].delivered_count;
            this.setMessage(
              `<strong>נציג יקר!</strong> בתאריך ${dateF} סופקו לך לחברת ${value} <strong>${deliveredCount}</strong> סימים וחוברו <strong>
${activeCount}</strong> סימים, המלאי שבירשותך הינו <strong>${deliveredCount - activeCount}</strong> סימים.`);
          });
        } else {
          this.setMessage(null);
        }
      } else {
        this.setMessage(null);
      }
    });
    this.getNewSims();
  }

  buildSims(value) {
    this.sims = value.map(x => {
      x['request_date'] = new Date(x['request_date']['$date']);
      x['done_date'] = x['done_date'] ? new Date(x['done_date']['$date']) : null;
      return x;
    }).sort((a, b) => (a.request_date < b.request_date) ? 1 : ((b.request_date < a.request_date) ? -1 : 0));
  }

  postNewSims() {
    if (this.newSims.valid && this.canActive) {
      this.request.post('/new_sims', this.newSims.value).subscribe((value: any[]) => {
        this.buildSims(value);
      });
    }
  }

  getNewSims() {
    this.request.get('/new_sims').subscribe(value => {
      this.buildSims(value);
    });
  }

  deleteNewSim(element) {
    const dialogRef = this.dialog.open(WarnningDialogComponent, {
      data: {
        title: 'מחק הזמנת סים',
        content: 'האם אתה בטוח שברצונך למחוק ההזמנה?',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.request.delete('/new_sims/' + element._id['$oid']).subscribe((value: any) => this.buildSims(value));
      }
    });
  }


}
