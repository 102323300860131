import {Component} from '@angular/core';
import {AuthService} from './auth.service';

@Component({
  selector: 'app-root',
  // template: '<router-outlet></router-outlet>',
  templateUrl: './app.component.html',
})
export class AppComponent {

  // showHeader;
  constructor(authService: AuthService) {
    new Promise(resolve => {
      const refresh = authService.refreshToken();
      if (refresh) {
        refresh.subscribe().add(resolve);
      }
    }).then();

  }

}
