import {Component, Input, OnInit, OnChanges, ViewChild, Output, EventEmitter} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {RequestsService} from "../../requests.service";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-admin-sim-release',
  templateUrl: './admin-sim-release.component.html',
  styleUrls: ['./admin-sim-release.component.css']
})
export class AdminSimReleaseComponent implements OnInit {
  newData;
  history;


  constructor(private requests: RequestsService) {
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.requests.get('/admin/sim_release').subscribe((value: any[]) => this.setData(value));
  }

  setData(value) {
    const newData = [];
    const history = [];
    value.forEach(x => x.status === 0 ? newData.push(x) : history.push(x));
    this.newData = newData;
    this.history = history;
  }

  post($event: any) {
    this.requests.post('/admin/sim_release', $event).subscribe((value: any[]) => this.setData(value));
  }
}

@Component({
  selector: 'app-admin-sim-release-table',
  templateUrl: './admin-sim-release-table.component.html',
  styleUrls: ['./admin-sim-release.component.css']
})
export class AdminSimReleaseTableComponent implements OnChanges {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input() data;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  active;

  dataSource: MatTableDataSource<any[]>;
  displayedColumns = ['phone_number', 'company', 'IMEI', 'status', 'created_date', 'comment', 'actions'];

  constructor() {
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource<any[]>(this.data);
    this.dataSource.paginator = this.paginator;
  }

  save(status?) {
    if (status !== undefined) {
      this.active.status = status;
    }
    this.action.emit(this.active);
  }
}
