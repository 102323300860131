import {Component, Input, OnInit} from '@angular/core';
import {UserSpaceDialogComponent} from '../user-space-dialog/user-space-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
})
export class CompanyCardComponent implements OnInit {
  @Input() company;

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
    console.log(this.company)
  }

  openLink(company) {
    if (company.link) {
      this.dialog.open(UserSpaceDialogComponent, {data: {company: company}});
      // window.open(company.link, '_blink');
    }
  }

  setValue(checked: boolean, alias: string) {
    this.company[alias] = checked ? this.company.sale_mail_alias : null;
  }
}
