import {RequestsService} from './requests.service';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    constructor(private requests: RequestsService) {
    }

    user;
    companies;
    tracks;
    sales;
    clients;
    clients_ids;
    config = {projectName: 'שוק השיווק'};
    users;

    setClients() {
        this.requests.get('/api/info/get_clients').subscribe(value => this.clients = value);
    }
}
